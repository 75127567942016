import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Field } from 'redux-form'

import { adaloBackendAxios } from 'utils/io/http/axios'
import ChipInput from 'components/Shared/ChipInput'
import Icon from 'components/Shared/Icon'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Button from 'components/Shared/Button'

import appPreviewPlaceholder from '../assets/onboarding-app-preview-placeholder.png'
import appLogoGraphic from '../assets/onboarding-app-logo-graphic.svg'

import { SKIP_APP_CATEGORY } from '../UserOnboarding'
import { SAMPLE_APP_DATA } from './constants'

type Props = {
  initialCategory?: string
  setAppCategory: (appCategory: string) => void
  loading: boolean
  appCategories: string[]
  setIsAppCategoryCustom: (isCustom: boolean) => void
}

const AppCategory: React.FC<Props> = ({
  initialCategory,
  setAppCategory,
  loading,
  setIsAppCategoryCustom,
  appCategories = [],
}) => {
  const title = 'What type of app would you like to build?'

  const label = 'Select an app type or input your own more specific app idea.'

  const placeholder = 'App type...'

  const onClick = (chip: string, isCustom = false) => {
    setAppCategory(chip)
    setIsAppCategoryCustom(isCustom)
  }

  return (
    <div className="onboarding-app-category-step">
      <h3>{title}</h3>
      <ChipInput
        initialValue={initialCategory}
        placeholder={placeholder}
        label={label}
        chips={appCategories}
        onClick={onClick}
        loading={loading}
        skipLabel="Try with a generic Starter App"
        skipValue={SKIP_APP_CATEGORY}
      />
    </div>
  )
}

type AppCategoryGraphicProps = {
  appCategory: string
  userName?: string | undefined
  isCustom?: boolean
}

export const AppCategoryGraphic: React.FC<AppCategoryGraphicProps> = ({
  appCategory,
  userName = 'You',
  isCustom = false,
}) => {
  const [previewBanner, setPreviewBanner] = useState('')
  const [previewIcon, setPreviewIcon] = useState('')

  const hasCustomCategory = Boolean(appCategory)

  const appName = hasCustomCategory ? appCategory : 'Your'

  useEffect(() => {
    if (!appCategory || appCategory === SKIP_APP_CATEGORY) {
      setPreviewBanner('')
      setPreviewIcon('')

      return
    }

    if (isCustom) {
      setPreviewBanner(SAMPLE_APP_DATA.previewBanner)
      setPreviewIcon(SAMPLE_APP_DATA.previewIcon)

      return
    }

    ;(async () => {
      const params = new URLSearchParams({ appCategory })

      const { previewBanner: newBanner, previewIcon: newIcon } =
        await adaloBackendAxios
          .get(`/app-category/preview`, {
            params,
          })
          .then(res => {
            return res.data as {
              previewBanner: string
              previewIcon: string
            }
          })
          .catch(err => {
            console.error(err)

            return { previewBanner: '', previewIcon: '' }
          })

      setPreviewIcon(newIcon)
      setPreviewBanner(newBanner)
    })()
      .then(() => {})
      .catch(() => {})
  }, [appCategory, isCustom])

  return (
    <div className="onboarding-graphics-app-category">
      <div
        className={classNames('onboarding-graphics-app-category__app-logo', {
          'onboarding-graphics-app-category__app-logo--empty':
            !hasCustomCategory,
        })}
      >
        <div className="onboarding-graphics-app-category__app-logo__preview-icon">
          <img src={previewIcon || appLogoGraphic} alt="App logo" />
        </div>

        <div className="onboarding-graphics-app-category__app-logo__preview-title">
          <h3>{appName} App</h3>
          <span>Created by {userName}</span>
        </div>
      </div>

      <div
        className={classNames(
          'onboarding-graphics-app-category__app-preview-browser',
          {
            'onboarding-graphics-app-category__app-preview-browser--selected':
              hasCustomCategory,
          }
        )}
      >
        <header>
          <div className="browser-buttons-graphic">
            <div className="browser-buttons-graphic__left-side">
              <div className="browser-buttons-graphic__left-side__circles">
                <div className="button-circle" />
                <div className="button-circle" />
                <div className="button-circle" />
              </div>
              <div className="browser-buttons-graphic__left-side__arrows">
                <Icon className="button-arrow" type="arrow-back" small />
                <Icon className="button-arrow" type="arrow-next" small />
              </div>
            </div>
            <div className="browser-buttons-graphic__address-bar">
              {`${
                hasCustomCategory
                  ? appName.toLowerCase().replace(/\s/g, '-')
                  : 'yourapp'
              }.adalo.com`}
            </div>
          </div>
        </header>
        <section>
          <div className="onboarding-graphics-app-category__app-preview__form">
            <div className="onboarding-graphics-app-category__app-preview__form__title">
              <h4>{hasCustomCategory ? `${appName} App` : 'My Awesome App'}</h4>
              <p>
                Quickly describe the app and then encourage users to sign up
                telling them the benefits & to join your community.
              </p>
            </div>
            <div className="onboarding-graphics-app-category__app-preview__form__inputs">
              <Field
                name="name"
                label="Full Name"
                placeholder="Enter full name..."
                component={WrappedInput}
                autoComplete="off"
              />
              <Field
                name="name"
                label="Email"
                placeholder="Enter email..."
                component={WrappedInput}
                autoComplete="off"
              />
              <Field
                name="name"
                label="Password"
                placeholder="Enter password..."
                component={WrappedInput}
                autoComplete="off"
              />
            </div>
            <Button disabled={!hasCustomCategory}>Signup</Button>
            <div className="onboarding-graphics-app-category__app-preview__form__login-link">
              Already have an account? <p>LOG IN</p>
            </div>
          </div>
          <img
            className={classNames(
              'onboarding-graphics-app-category__app-preview__banner',
              {
                'onboarding-graphics-app-category__app-preview__banner--empty':
                  !hasCustomCategory,
              }
            )}
            src={previewBanner || appPreviewPlaceholder}
            alt={`${appCategory} App Preview`}
          />
        </section>
      </div>

      <p
        className={classNames('onboarding-graphics-app-category__disclaimer', {
          'onboarding-graphics-app-category__disclaimer--hidden':
            !hasCustomCategory,
        })}
      >
        This is a preview of your app. You’ll be able to change this later.
      </p>
    </div>
  )
}

export default AppCategory
