import React from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import TrialModal from 'components/Shared/TrialWarning/TrialModal'
import Stat from 'components/Shared/Stat/index.tsx'
import Icon from 'components/Shared/Icon'
import Button from 'components/Shared/Button'
import mouthFace from 'components/Shared/Icon/icons/money-mouth-face.png'

import {
  getModalVisible,
  getAnnualToggle,
  setAnnualFlag,
  setSwitchToYearlyFlag,
  getSwitchToYearlyFlag,
  setSelectedPlanValue,
  setPaymentRouteType,
  showModal,
} from 'ducks/trialWarning'
import { getPricingPlans, getSubscription } from 'ducks/billing'
import { getCurrentUser, userIsAdmin } from 'ducks/users/index.ts'

import {
  getPlanName,
  getLimit,
  getPlanPerks,
  getPlanPrice,
  isLegacyPlanType,
} from 'utils/billing.ts'

import '../../FreeFlowSection/FreeFlow.scss'
import './PlansAndServices.scss'

const generatePlanStats = (
  planName,
  planLimit,
  planCharge,
  nextBillDate,
  billingCycle,
  setToCancel
) => {
  const stats = [
    {
      id: 'name',
      title: 'Current Plan',
      stat: planName,
    },
  ]

  if (planLimit) {
    stats.push({
      id: 'limit',
      title: 'Monthly App Actions',
      stat: planLimit.toLocaleString('en-US'),
    })
  }

  if (planCharge) {
    const planChargeText =
      billingCycle === 'yearly' ? `$${planCharge}/year` : `$${planCharge}/month`

    stats.push({
      id: 'cost',
      title: 'Cost',
      stat: planChargeText,
    })
  }

  if (nextBillDate) {
    stats.push({
      id: 'next-payment',
      title: 'Next Payment',
      stat: !setToCancel
        ? moment(nextBillDate)?.format('MMM D, YYYY')
        : 'No future payments',
    })
  }

  return stats
}

const handleSwitchToYearly = (props, planType) => {
  const { setAnnualFlag, setSwitchToYearlyFlag, setSelectedPlanValue } = props

  setAnnualFlag(true)
  setSwitchToYearlyFlag(true)
  setSelectedPlanValue(planType)
}

const handleChangePlan = (setPaymentRouteType, showModal) => {
  setPaymentRouteType('upgrade')
  showModal()
}

const PlanAndServices = props => {
  const {
    organization,
    subscription,
    switchToYearly,
    pricingPlans,
    setPaymentRouteType,
    showModal,
  } = props
  const currentUser = useSelector(getCurrentUser)

  if (!pricingPlans) return null

  const { planType, billingCycle } = organization

  const planName = getPlanName(planType, pricingPlans)
  const isAdmin = userIsAdmin(organization, currentUser)
  const planCharge = isAdmin
    ? getPlanPrice(planType, pricingPlans, billingCycle)
    : null
  const planLimit = getLimit(planType, pricingPlans, 'actions')
  const isLegacyPlan = isLegacyPlanType(planType)

  const { nextBillDate, setToCancel } = subscription || {}

  const planStats = generatePlanStats(
    planName,
    planLimit,
    planCharge,
    nextBillDate,
    billingCycle,
    setToCancel
  )
  const isMaintenancePlan = planType === 'maintenance'

  const planPerks = getPlanPerks(planType, pricingPlans)

  const renderPaymentSettingsForm = () => {
    const { fetchOrganizations, appId, annual, organization } = props

    return (
      <TrialModal
        organizationId={organization?.id}
        forceRefresh={fetchOrganizations}
        appId={appId}
        initialValues={{ plan: planType, annual }}
      />
    )
  }

  const showSwitchToYearly =
    !isLegacyPlan && !isMaintenancePlan && billingCycle !== 'yearly' && isAdmin

  return (
    <div id="plan-and-services" className="plan-services-section">
      <div className="plan-services-header">
        <h3 className="billing-usage-chart-header-text">Plan & Services</h3>
        <a
          className="settings-link-button"
          onClick={() => handleChangePlan(setPaymentRouteType, showModal)}
          data-adalo-id="change-plan-billing"
        >
          Change Plan
        </a>
      </div>
      <div className="plan-service-stats">
        {planStats.map(statInfo => (
          <Stat
            key={statInfo.title}
            {...statInfo}
            legacyWarning={statInfo.id === 'name' && isLegacyPlan}
          />
        ))}
      </div>
      {planPerks ? (
        <ul className="plan-services">
          <p className="settings-content-label">What's Included:</p>
          {planPerks.map(text => (
            <Perk text={text} key={text} />
          ))}
        </ul>
      ) : null}
      {isLegacyPlan ? (
        <div className="legacy-plan-warning">
          <Icon type="info" color="orange" />
          <p>
            You have until July 2023 to switch to a new plan.{' '}
            <a
              id="legacy-plan-warning-link"
              target="_blank"
              rel="noreferrer"
              href="https://help.adalo.com/resources/adalo-pricing-legacy-accounts"
            >
              Learn More
            </a>
          </p>
        </div>
      ) : null}
      {showSwitchToYearly ? (
        <SwitchToYearlyPlan
          props={props}
          planType={planType}
          planCharge={planCharge}
          planName={planName}
        />
      ) : null}
      {switchToYearly && renderPaymentSettingsForm()}
    </div>
  )
}

const Perk = ({ text }) => (
  <li className="service">
    <Icon className="service-green-check" color="teal" type="list-check" />
    {text}
  </li>
)

const SwitchToYearlyPlan = ({ props, planType, planCharge, planName }) => {
  const savings = 0.2 * Number.parseFloat(planCharge) * 12
  const savingsText = `Save 20% by switching to ${planName} Plan Yearly (That's a $${savings} savings!)`

  return (
    <div className="shaded-content-box">
      <div className="switch-yearly-plan">
        <img src={mouthFace} className="money-mouth-image2" alt="" />
        <h4> New Yearly Pricing </h4>
      </div>
      <p>{savingsText}</p>
      <Button small teal onClick={() => handleSwitchToYearly(props, planType)}>
        SWITCH TO YEARLY
      </Button>
    </div>
  )
}

const mapStateToProps = (state, { match, organization }) => ({
  appId: match.params.appId,
  modalVisible: getModalVisible(state),
  annual: getAnnualToggle(state),
  switchToYearly: getSwitchToYearlyFlag(state),
  pricingPlans: getPricingPlans(state),
  subscription: getSubscription(state, organization?.id),
})

const connected = connect(mapStateToProps, {
  setAnnualFlag,
  setSwitchToYearlyFlag,
  setSelectedPlanValue,
  showModal,
  setPaymentRouteType,
})(PlanAndServices)

export default withRouter(connected)
