import React, { createContext, useEffect, useState } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFlags } from 'flags'
import { setFeatureFlags } from 'ducks/featureFlags'
import { fetchPricingPlans } from 'ducks/billing'
import { getCurrentUserToken } from 'ducks/users/index.ts'

import UserEmailValidation from 'components/Onboarding/UserEmailValidation'
import history from '../../history'

// Sentry.io & Google Analytics
import SentryWrapper from './Sentry'
import Analytics from './Analytics'

import Editor from '../Editor'
import Nav from '../Nav'
import Apps from '../Apps'
import Navbar, { Spacer } from './Navbar'
import Body from './Body'
import NewApp from '../NewApp'
import MaintenanceMode from '../MaintenanceMode'
import InitialApp from '../Auth/InitialApp'
import InitialTeam from '../Auth/InitialTeam'
import UserOnboarding from '../Onboarding/UserOnboarding'
import AppRoomLeaver from '../AppRooms/AppRoomLeaver'
import Settings, { SettingsRoutes } from '../Settings'
import PaymentSuccess from '../Shared/TrialWarning/PaymentSuccess'
import UnsubscribeComplete from '../Shared/TrialWarning/UnsubscribeComplete.tsx'

import AuthRedirect from './AuthRedirect'
import Notifications from './Notifications'
import OfflineBanner from './OfflineBanner'
import { DevelopersRoutes } from '../Developers'
import collectionAPIDocs from '../collectionAPIDocs'

// Admin
import AdminRoutes from '../Admin'

// Auth
import AuthRoutes from '../Auth'

import './App.css'

export const NavbarContext = createContext()

const App = () => {
  const flags = useFlags()
  const dispatch = useDispatch()

  const currentUserToken = useSelector(state => getCurrentUserToken(state))

  useEffect(() => {
    dispatch(setFeatureFlags(flags))

    // Delay fetching pricing plans until after a user signs in.
    if (currentUserToken) {
      dispatch(fetchPricingPlans())
    }
  }, [dispatch, flags, currentUserToken])

  const children = renderChildren()

  if (process.env.NODE_ENV === 'production') {
    return <SentryWrapper>{children}</SentryWrapper>
  }

  return children
}

const renderChildren = () => {
  const [showNavbar, setShowNavbar] = useState(true)

  return (
    <Router history={history}>
      <Analytics>
        <OfflineBanner />
        <Notifications />
        <AuthRedirect />
        <AppRoomLeaver />
        <Switch>
          <Route
            path={[
              '/login',
              '/signup',
              '/forgot-password',
              '/recover-password',
              '/accept-invite',
              '/logout',
            ]}
            component={AuthRoutes}
          />
          <Route exact path="/onboarding" component={UserOnboarding} />
          <Route
            exact
            path="/email-validation"
            component={UserEmailValidation}
          />
          <Route exact path="/team-setup" component={InitialTeam} />
          <Route exact path="/first-app-setup" component={InitialApp} />
          <Route path="/apps/:appId/api-docs" component={collectionAPIDocs} />
          <Route
            render={() => (
              <div className="app">
                <NavbarContext.Provider value={{ showNavbar, setShowNavbar }}>
                  {showNavbar ? (
                    <Navbar>
                      <Switch>
                        <Route path="/apps/:appId/:tabName" component={Nav} />
                        <Route>
                          <React.Fragment>
                            <Spacer />
                          </React.Fragment>
                        </Route>
                      </Switch>
                    </Navbar>
                  ) : null}
                  <Body>
                    <Switch>
                      <Route
                        exact
                        path="/choose-pricing-plan/:organizationId"
                        component={MaintenanceMode}
                      />
                      <Route
                        exact
                        path="/organizations/:organizationId/subscribe-for-maintenance"
                        component={MaintenanceMode}
                      />
                      <Route exact path="/" component={Apps} />
                      <Route path="/settings" render={SettingsRoutes} />
                      <Route path="/developers" render={DevelopersRoutes} />
                      <Route
                        exact
                        path="/new"
                        render={() => (
                          <Apps modalProps={{ className: 'new-app-modal' }}>
                            <NewApp />
                          </Apps>
                        )}
                      />

                      <Route
                        exact
                        path="/apps/:appId/settings"
                        component={Settings}
                      />
                      <Route
                        exact
                        path="/apps/:appId/payment-success"
                        component={PaymentSuccess}
                      />
                      <Route
                        exact
                        path="/apps/:appId/unsubscribe-success"
                        component={UnsubscribeComplete}
                      />
                      <Route path="/admin" component={AdminRoutes} />

                      <Route
                        path="/apps/:appId"
                        component={({ match }) => (
                          <Redirect
                            to={`/apps/${match.params.appId}/screens/add`}
                            replace
                          />
                        )}
                        exact
                      />

                      <Route path="/apps/:appId" component={Editor} />
                    </Switch>
                  </Body>
                </NavbarContext.Provider>
              </div>
            )}
          />
        </Switch>
      </Analytics>
    </Router>
  )
}

export default App
