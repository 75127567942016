import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import { updateObject } from '../../../../ducks/editor/objects'
import MenuControl from '../../Inspect/Libraries/MenuControl'
import VisibilityForm from './VisibilityForm'
import RightPanelCollapsible from '../RightPanelCollapsible'

const ALWAYS = ' always'
const SOMETIMES = ' sometimes'

const VISIBILITY_OPTIONS = [
  { label: 'Always Visible', value: ALWAYS },
  { label: 'Sometimes Visible', value: SOMETIMES },
]

function Options({ object, updateObject }) {
  const handleChangeBinding = useCallback(
    binding => {
      updateObject(object.id, { visibility: binding })
    },
    [updateObject, object.id]
  )

  return (
    <div className="right-panel-visibility-options">
      <VisibilityForm
        object={object}
        binding={object.visibility}
        onChange={handleChangeBinding}
      />
    </div>
  )
}

function Visibility({ object, expanded, onClose, onExpand, updateObject }) {
  const [optionsVisible, setOptionsVisible] = useState(false)

  const handleChangeVisibility = useCallback(
    ({ visible }) => {
      setOptionsVisible(visible === SOMETIMES)

      if (visible === ALWAYS && object.visibility) {
        updateObject(object.id, { visibility: null })
      }
    },
    [updateObject, object.id, object.visibility]
  )

  const tooltip = (
    <a
      href="https://help.adalo.com/component-basics/changing-a-components-visibility"
      target="_blank"
      rel="noreferrer"
    >
      Learn more.
    </a>
  )

  const showOptions = optionsVisible || !!object.visibility
  const value = showOptions ? SOMETIMES : ALWAYS

  return (
    <RightPanelCollapsible
      expanded={expanded}
      visible={showOptions}
      collapsedClassName="right-panel-visibility-collapsed"
      visibilityControl={
        <MenuControl
          name="visible"
          displayName="Visibility"
          options={VISIBILITY_OPTIONS}
          value={value}
          onChange={handleChangeVisibility}
          rowHeight={40}
          tooltip={tooltip}
        />
      }
      iconType="magic-visibility"
      collapsedText="Sometimes Visible"
      onClose={onClose}
      onExpand={onExpand}
      submitLabel="Done"
    >
      {showOptions ? (
        <Options object={object} updateObject={updateObject} />
      ) : null}
    </RightPanelCollapsible>
  )
}

export default connect(null, { updateObject })(Visibility)
