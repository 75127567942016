import { adaloBackendAxios } from 'utils/io/http/axios'

const RESET = Symbol('RESET')
const SHOW_MODAL = Symbol('SHOW_TRIAL_MODAL')
const HIDE_MODAL = Symbol('HIDE_TRIAL_MODAL')
const ANNUAL_FLAG = Symbol('ANNUAL_FLAG')
const UPDATE_PAYMENT_SETTINGS = Symbol('UPDATE_PAYMENT_SETTING')
const UPDATE_CARD_FLAG = Symbol('UPDATE_CARD_FLAG')
const PLAN_SELECTED_FLAG = Symbol('PLAN_SELECTED_FLAG')
const SELECTED_PLAN_VALUE = Symbol('SELECTED_PLAN_VALUE')
const PAYMENT_ROUTE_TYPE = Symbol('PAYMENT_ROUTE_TYPE')
const SWITCH_TO_YEARLY = Symbol('SWITCH_TO_YEARLY')
const SHOW_UPGRADE_MODAL = Symbol('SHOW_UPGRADE_MODAL')
const SHOW_ORGANIZATION_DETAILS = Symbol('SHOW_ORGANIZATION_DETAILS')
const CLOSABLE_FLAG = Symbol('CLOSABLE_FLAG')

const UPDATE = 'UPDATE_SUBSCRIPTION'
const FETCH = 'FETCH_SUBSCRIPTION'
const FETCH_SUBSCRIPTION_PREVIEW = 'FETCH_SUBSCRIPTION_PREVIEW'

const INITIAL_STATE = {
  visible: false,
  annual: true,
  updateCardFlag: false,
  updatePaymentSettings: false,
  planSelected: false,
  selectedPlanValue: '',
  paymentRouteType: 'billing',
  switchToYearly: false,
  closable: true,
  showOrganizationDetailsFlag: false,
}

// Reducer
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET: {
      return INITIAL_STATE
    }

    case SHOW_MODAL: {
      return { ...state, visible: true }
    }
    case HIDE_MODAL: {
      return { ...state, visible: false }
    }

    case SHOW_UPGRADE_MODAL: {
      const { meta } = action

      return {
        ...state,
        visible: true,
        paymentRouteType: meta.paymentRouteType,
      }
    }

    case SHOW_ORGANIZATION_DETAILS: {
      const { meta } = action

      return {
        ...state,
        showOrganizationDetailsFlag: meta.showOrganizationDetailsFlag,
      }
    }

    case ANNUAL_FLAG: {
      const { meta } = action

      return { ...state, annual: meta.annual }
    }

    case UPDATE_CARD_FLAG: {
      const { meta } = action

      return { ...state, updateCardFlag: meta.updateCardFlag }
    }

    case PAYMENT_ROUTE_TYPE: {
      const { meta } = action

      return { ...state, paymentRouteType: meta.paymentRouteType }
    }

    case PLAN_SELECTED_FLAG: {
      const { meta } = action

      return { ...state, planSelected: meta.planSelected }
    }

    case SELECTED_PLAN_VALUE: {
      const { meta } = action

      return { ...state, selectedPlanValue: meta.selectedPlanValue }
    }

    case UPDATE_PAYMENT_SETTINGS: {
      const { meta } = action

      return { ...state, updatePaymentSettings: meta.updatePaymentSettings }
    }

    case SWITCH_TO_YEARLY: {
      const { meta } = action

      return { ...state, switchToYearly: meta.switchToYearly }
    }

    case CLOSABLE_FLAG: {
      const { meta } = action

      return { ...state, closable: meta.closable }
    }

    case `${UPDATE}_FULFILLED`:
    case UPDATE:
    case FETCH:
    case `${FETCH}_FULFILLED`: {
      const { payload, meta } = action

      return {
        ...state,
        [meta.organizationId]: payload.data,
      }
    }

    case `${FETCH_SUBSCRIPTION_PREVIEW}_FULFILLED`: {
      const { payload } = action

      return {
        ...state,
        proratedTotal: payload.data.proratedTotal,
        unusedTimeTotal: payload.data.unusedTimeTotal,
        couponTotal: payload.data.couponTotal,
      }
    }

    default: {
      return state
    }
  }
}

// Actions
export const reset = () => ({ type: RESET })

export const showModal = () => ({ type: SHOW_MODAL })

export const hideModal = () => ({ type: HIDE_MODAL })

export const setAnnualFlag = flag => ({
  type: ANNUAL_FLAG,
  meta: { annual: flag },
})

export const showUpgradeModal = value => ({
  type: SHOW_UPGRADE_MODAL,
  meta: { paymentRouteType: value },
})

export const showOrganizationDetails = value => ({
  type: SHOW_ORGANIZATION_DETAILS,
  meta: { showOrganizationDetailsFlag: value },
})

export const setPaymentRouteType = value => ({
  type: PAYMENT_ROUTE_TYPE,
  meta: { paymentRouteType: value },
})

export const setPlanSelectedFlag = flag => ({
  type: PLAN_SELECTED_FLAG,
  meta: { planSelected: flag },
})

export const setSelectedPlanValue = value => ({
  type: SELECTED_PLAN_VALUE,
  meta: { selectedPlanValue: value },
})

export const setUpdateCardFlag = flag => ({
  type: UPDATE_CARD_FLAG,
  meta: { updateCardFlag: flag },
})

export const setUpdatePaymentSettingsFlag = flag => ({
  type: UPDATE_PAYMENT_SETTINGS,
  meta: { updatePaymentSettings: flag },
})

export const setSwitchToYearlyFlag = flag => ({
  type: SWITCH_TO_YEARLY,
  meta: { switchToYearly: flag },
})

export const setClosableFlag = flag => ({
  type: CLOSABLE_FLAG,
  meta: { closable: flag },
})

export const saveSubscription = (orgId, data) => ({
  type: UPDATE,
  payload: adaloBackendAxios.put(`/organizations/${orgId}/subscription`, data),
  meta: { organizationId: orgId },
})

export const fetchSubscription = orgId => ({
  type: FETCH,
  payload: adaloBackendAxios.get(`/organizations/${orgId}/subscription`),
  meta: { organizationId: orgId },
})

export const cancelSubscription = orgId => ({
  type: UPDATE,
  payload: adaloBackendAxios.delete(`/organizations/${orgId}/subscription`),
  meta: { organizationId: orgId },
})

export const fetchCoupon = async coupon => {
  try {
    const payload = await adaloBackendAxios.get(`/organizations/coupon`, {
      params: { coupon },
    })

    return payload.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    }
  }
}

export const fetchProratedCharge = (
  orgId,
  planType,
  annual,
  coupon,
  additionalPublishedApps,
  additionalTeamMembers
) => ({
  type: FETCH_SUBSCRIPTION_PREVIEW,
  payload: adaloBackendAxios.get(
    `/organizations/${orgId}/subscription-amount`,
    {
      params: {
        planType,
        annual,
        coupon,
        additionalPublishedApps,
        additionalTeamMembers,
      },
    }
  ),
  meta: { organizationId: orgId },
})

// Selectors

export const getModalVisible = state => {
  return state.trialWarning.visible
}

export const getShowOrganizationDetailsFlag = state => {
  return state.trialWarning.showOrganizationDetailsFlag
}

export const getAnnualToggle = state => {
  return state.trialWarning.annual
}

export const getUpdateCardFlag = state => {
  return state.trialWarning.updateCardFlag
}

export const getPlanSelectedFlag = state => {
  return state.trialWarning.planSelected
}

export const getSelectedPlanValue = state => {
  return state.trialWarning.selectedPlanValue
}

export const getUpdatePaymentSettingsFlag = state => {
  return state.trialWarning.updatePaymentSettings
}

export const getSubscription = (state, orgId) => {
  return state.trialWarning[orgId]
}

export const getPaymentRouteType = state => {
  return state.trialWarning.paymentRouteType
}

export const getSwitchToYearlyFlag = state => {
  return state.trialWarning.switchToYearly
}

export const getProratedCharge = state => {
  return state.trialWarning.proratedTotal
}

export const getUnusedTimeAmount = state => {
  return state.trialWarning.unusedTimeTotal
}

export const getCouponAmount = state => {
  return state.trialWarning.couponTotal
}

export const getClosableFlag = state => {
  return state.trialWarning.closable
}
