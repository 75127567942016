import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { change } from 'redux-form'

import {
  getAnnualToggle,
  getUpdateCardFlag,
  getUpdatePaymentSettingsFlag,
  setUpdateCardFlag,
  setUpdatePaymentSettingsFlag,
  setPlanSelectedFlag,
  getSelectedPlanValue,
  getSubscription,
  getPaymentRouteType,
  setSwitchToYearlyFlag,
  fetchProratedCharge,
  getClosableFlag,
  getShowOrganizationDetailsFlag,
} from 'ducks/trialWarning'
import { getPricingPlans } from 'ducks/billing'
import {
  ACTION_UPGRADE,
  ACTION_DOWNGRADE,
  ACTION_SWITCH_TO_MONTHLY,
  ACTION_SWITCH_TO_YEARLY,
  getPlanName,
  getPlanActions,
  normalizeSubscription,
  isCurrentPlanType,
  hasBusinessPlan,
} from 'utils/billing.ts'

import {
  PlanChangeContext,
  PUBLISHED_APPS_STEP,
  PAYMENT_STEP,
  TEAM_MEMBERS_STEP,
} from 'components/Shared/TrialWarning/PlanChangeContext'

import { IconButton } from 'components/Shared/Icon'
import PaymentForm from 'components/Settings/Billing/PaymentForm'
import Modal from 'components/Shared/Modal'
import history from '../../../history'
import { getCurrentUser } from '../../../ducks/users'
import { getCurrentOrganization } from '../../../ducks/organizations'

const FORM_NAME = 'trialModalSignup'

const SUBMIT_LABELS = {
  [ACTION_UPGRADE]: 'Upgrade',
  [ACTION_DOWNGRADE]: 'Downgrade',
  [ACTION_SWITCH_TO_MONTHLY]: 'Switch to Monthly',
  [ACTION_SWITCH_TO_YEARLY]: 'Switch to Yearly',
}

const getSubmitLabel = action => {
  if (action && action in SUBMIT_LABELS) {
    return SUBMIT_LABELS[action]
  }

  return 'Upgrade'
}

class PayModal extends Component {
  static contextType = PlanChangeContext

  closeModal = () => {
    const {
      match,
      setUpdateCardFlag,
      setUpdatePaymentSettingsFlag,
      setSwitchToYearlyFlag,
      onClickBackChangePlan,
    } = this.props

    setUpdateCardFlag(false)
    setUpdatePaymentSettingsFlag(false)
    setSwitchToYearlyFlag(false)
    if (onClickBackChangePlan) onClickBackChangePlan()

    history.push(`/apps/${match.params.appId}/screens`)
  }

  renderContent() {
    const {
      submitting,
      error,
      annual,
      updatePaymentSettings,
      onSubmit,
      onClickBackChangePlan,
      selectedPlanValue,
      updateCardFlag,
      setUpdateCardFlag,
      setPlanSelectedFlag,
      subscription = {},
      setSwitchToYearlyFlag,
      couponCode,
      organizationId,
      pricingPlans,
      change,
      isCurrentlyLegacyPlan,
      currentUser,
      currentOrganization,
      showOrganizationDetails,
    } = this.props

    const { planType, planInterval } = normalizeSubscription(subscription)
    const planActions = getPlanActions(planType, planInterval, annual)
    const planAction = planActions[selectedPlanValue]
    const {
      setCurrentStep,
      currentStep,
      currentPublishedApps,
      appLimit,
      currentTeamMemberCount,
      teamMemberLimit,
    } = this.context

    const subscriptionHeading = updatePaymentSettings
      ? planType
      : selectedPlanValue

    const planName = getPlanName(subscriptionHeading, pricingPlans)

    let onClickBack = () => {
      setPlanSelectedFlag(updateCardFlag)
      setSwitchToYearlyFlag(updateCardFlag)
      setUpdateCardFlag(false)
      change(FORM_NAME, 'useNewCard', false)
    }

    if (currentStep === PAYMENT_STEP) {
      if (
        currentTeamMemberCount > teamMemberLimit &&
        !hasBusinessPlan(selectedPlanValue)
      ) {
        onClickBack = () => setCurrentStep(TEAM_MEMBERS_STEP)
      } else if (currentPublishedApps > appLimit || isCurrentlyLegacyPlan) {
        onClickBack = () => setCurrentStep(PUBLISHED_APPS_STEP)
      }
    }

    if (updatePaymentSettings) {
      onClickBack = onClickBackChangePlan
    }

    let title
    let subheader

    if (isCurrentPlanType(subscriptionHeading)) {
      const type = annual ? 'Yearly' : 'Monthly'

      title =
        subscriptionHeading === 'maintenance'
          ? 'Your app will be ready whenever you are.'
          : `You're one step away from the ${planName} ${type} Plan!`

      subheader = `${planName} ${type} Plan`
    } else {
      if (annual) {
        if (subscriptionHeading === 'business') {
          title = "You're one step away from Business Plan Yearly!"
          subheader = 'Business Plan Yearly'
        } else {
          title = "You're one step away from Pro Plan Yearly!"
          subheader = 'Pro Plan Yearly'
        }
      } else {
        if (subscriptionHeading === 'business') {
          title = "You're one step away from the Business Plan!"
          subheader = 'Monthly Business Plan'
        } else {
          title = "You're one step away from the Pro Plan!"
          subheader = 'Monthly Pro Plan'
        }
      }
    }

    return (
      <>
        {updatePaymentSettings ? (
          <Modal.Header title="Update Payment Settings" />
        ) : (
          <Modal.Header title={title} />
        )}
        <Modal.Content
          style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto',
          }}
        >
          {showOrganizationDetails && (
            <div className="organization-details">
              Hi{' '}
              <p className="organization-details-username">
                {currentUser.name}
              </p>
              ! Let's upgrade{' '}
              <p className="organization-details-name">
                {currentOrganization.name}
              </p>{' '}
              😊
            </div>
          )}
          <Modal.Container>
            {!updatePaymentSettings && (
              <div className="payment-form-subheader">{subheader}</div>
            )}
            {error && !submitting && (
              <div className="pay-settings-alert">{error}</div>
            )}
            <PaymentForm
              couponCode={couponCode}
              organizationId={organizationId}
              planName={planName}
              submitting={submitting}
            />
          </Modal.Container>
        </Modal.Content>
        <Modal.Actions>
          {onClickBackChangePlan && (
            <Modal.Button
              type="button"
              disabled={submitting}
              text
              onClick={onClickBack}
            >
              Back
            </Modal.Button>
          )}
          {updatePaymentSettings ? (
            <Modal.Button
              large
              type="submit"
              onClick={onSubmit}
              loading={submitting}
            >
              Update
            </Modal.Button>
          ) : (
            <Modal.Button
              large
              type="submit"
              onClick={onSubmit}
              loading={submitting}
            >
              {getSubmitLabel(planAction)}
            </Modal.Button>
          )}
        </Modal.Actions>
      </>
    )
  }

  render() {
    const { paymentRouteType, onCancel, closableFlag } = this.props

    if (paymentRouteType === 'billing') {
      return (
        <Modal size="lg" onClose={closableFlag ? onCancel : null}>
          {closableFlag && (
            <div className="settings-page-close-button">
              <IconButton type="close-big" onClick={this.closeModal} />
            </div>
          )}
          {this.renderContent()}
        </Modal>
      )
    } else {
      return this.renderContent()
    }
  }
}

const mapStateToProps = (state, { organizationId }) => {
  return {
    updateCardFlag: getUpdateCardFlag(state),
    closableFlag: getClosableFlag(state),
    updatePaymentSettings: getUpdatePaymentSettingsFlag(state),
    annual: getAnnualToggle(state),
    selectedPlanValue: getSelectedPlanValue(state),
    subscription: getSubscription(state, organizationId),
    paymentRouteType: getPaymentRouteType(state),
    pricingPlans: getPricingPlans(state),
    currentUser: getCurrentUser(state),
    currentOrganization: getCurrentOrganization(state),
    showOrganizationDetails: getShowOrganizationDetailsFlag(state),
  }
}

const connected = connect(mapStateToProps, {
  setUpdateCardFlag,
  setUpdatePaymentSettingsFlag,
  setPlanSelectedFlag,
  setSwitchToYearlyFlag,
  fetchProratedCharge,
  change,
})(PayModal)

export default withRouter(connected)
