import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getApp, updateApp } from 'ducks/apps'
import { isSupportedOnWeb } from 'utils/platform'

import MenuControl from '../../../Editor/Inspect/Libraries/MenuControl'

import { PREVIEW_URL, WEB_URL } from '../../../../constants'
import { getDomains } from '../../../../ducks/domains'

class Cloneable extends Component {
  handleChange = newValue => {
    const { match, updateApp } = this.props
    const { appId } = match.params
    updateApp(appId, newValue)
  }

  getPreviewURL = value => {
    const { match } = this.props
    const { appId } = match.params

    if (value === 'screens') {
      return `${PREVIEW_URL}/${appId}` || ''
    } else {
      return `${PREVIEW_URL}/${appId}` || ''
    }
  }

  getCurrentDomain = (useAppDomain = false) => {
    let { app, domainId, domains } = this.props

    if (!domains || !app) return null

    domainId = useAppDomain ? app?.DomainId : domainId

    const domain = domains.filter(d => d.id === domainId)[0]

    if (!domain) return `${app.Organization.subdomain}.${WEB_URL}`

    return `${domain.domain}`
  }

  getWebURL = value => {
    const { app } = this.props
    const subDomain = this.getCurrentDomain(true)
    const appPath = app.path
    const protocol = window.location.protocol

    if (value === 'screens') {
      return `${protocol}//${subDomain}/${appPath}` || ''
    } else {
      return `${protocol}//${subDomain}/${appPath}` || ''
    }
  }

  render() {
    const label = 'Allow anyone to clone this app?'
    const { app } = this.props
    const value = app.visibility || false

    const options = [
      { label: 'Not Cloneable', value: false },
      { label: 'Cloneable (Screens Only)', value: 'screens' },
      { label: 'Cloneable (Screens & Data)', value: 'data' },
    ]

    const tooltip = (
      <a
        href="https://help.adalo.com/settings/copying-vs-cloning-your-app"
        target="_blank"
        rel="noreferrer"
      >
        Learn more.
      </a>
    )

    let previewURL

    if (value && isSupportedOnWeb(app.primaryPlatform)) {
      previewURL = this.getWebURL(value)
    } else {
      previewURL = this.getPreviewURL(value)
    }

    return (
      <div className="app-settings-clone-menu-control">
        <MenuControl
          displayName={label}
          onChange={this.handleChange}
          name="visibility"
          value={value}
          options={options}
          tooltip={tooltip}
        />
        {value ? (
          <div>
            <p>Cloneable Link</p>
            <div className="clone-url">
              <a>{previewURL}</a>
            </div>
            <div className="app-settings-clone-button-wrapper">
              <a
                href={previewURL}
                target="_blank"
                rel="noopener noreferrer"
                className="app-settings-clone-button"
              >
                Open
              </a>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params

  const app = getApp(state, appId)
  let domains = []

  if (app?.Organization?.id) {
    domains = getDomains(state, app.Organization.id)
  }

  return { app, domains }
}

export default withRouter(connect(mapStateToProps, { updateApp })(Cloneable))
