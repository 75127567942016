import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { isEqual as _isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { getCurrentUser } from 'ducks/users/index.ts'
import {
  getOrganizations,
  setCurrentOrganization,
  fetchOrganization,
  getOrganizationLastApp,
  getCurrentOrganization,
} from 'ducks/organizations'
import { resetTeamAppsList } from 'ducks/apps'
import { getOrgInitials } from 'utils/titles'
import { logoutMaker } from 'utils/auth'

import Profile from '../../Shared/Profile'

const PopulatedTeamSwitcher = () => {
  const user = useSelector(state => getCurrentUser(state))

  if (!user) {
    return null
  }

  return <TeamSwitcher user={user} />
}

const TeamSwitcher = ({ user }) => {
  const wrapperRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const colors = ['teal', 'orange', 'yellow']

  const organizations = useSelector(state => getOrganizations(state), _isEqual)

  const currentOrganization = useSelector(state =>
    getCurrentOrganization(state)
  )

  const orgIndex =
    organizations.findIndex(org => org?.id === currentOrganization?.id) || 0

  const orgInitials = getOrgInitials(currentOrganization?.name)
  const orgColor = colors[orgIndex % colors.length]

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const handleToggle = () => {
    setExpanded(!expanded)
  }

  const location = useLocation()
  const baseURL = getBaseURL(location)

  return (
    <div className="navbar-user-info" ref={wrapperRef}>
      <a className="navbar-user-avatar" onClick={handleToggle}>
        <Profile color={orgColor} profileText={orgInitials} />
      </a>
      {expanded ? (
        <div
          className={classNames('navbar-user-menu expand-width', { expanded })}
        >
          {organizations?.length > 1 ? (
            <>
              <div
                className="navbar-team-switcher"
                data-adalo-id="team-switcher"
              >
                {organizations.map((organization, index) => {
                  const color = colors[index % colors.length]

                  return (
                    <OrgListItem
                      key={organization.id}
                      organization={organization}
                      user={user}
                      color={color}
                      currentOrganization={currentOrganization}
                    />
                  )
                })}
              </div>
              <hr />
            </>
          ) : null}
          <Link
            to={getSettingsLink(baseURL)}
            onClick={handleToggle}
            data-adalo-id="team-switcher-item-settings"
          >
            Settings
          </Link>
          <hr />
          <a
            href="https://help.adalo.com"
            target="_blank"
            rel="noopener noreferrer"
            data-adalo-id="team-switcher-item-help"
          >
            Help & Documentation
          </a>
          <hr />
          {user?.developer ? (
            <>
              <Link
                to={getDevelopersLink(baseURL)}
                data-adalo-id="team-switcher-item-developers"
              >
                Developers
              </Link>
              <hr />
            </>
          ) : null}
          {user.admin ? (
            <React.Fragment>
              <Link to="/admin" data-adalo-id="team-switcher-item-admin">
                Admin
              </Link>
              <hr />
            </React.Fragment>
          ) : null}
          <a
            href="https://www.adalo.com/hire-an-expert?utm_source=product&utm_campaign=app_adalo_expert&utm_content=team_switcher"
            target="_blank"
            rel="noopener noreferrer"
            data-adalo-id="team-switcher-item-hire-expert"
            className="team-switcher-item-hire-expert"
          >
            Hire an Expert
          </a>
          <hr />
          <a onClick={handleLogout} data-adalo-id="team-switcher-item-signout">
            Sign Out
          </a>
        </div>
      ) : null}
    </div>
  )
}

export const handleLogout = () => {
  logoutMaker()
  window.location.href = '/'
}

export const getBaseURL = location => {
  const match = location?.pathname?.match(/^\/apps\/([\w\-]+)/)

  if (match) {
    const appId = match[1]

    return `/apps/${appId}/screens`
  }

  return ''
}

export const getSettingsLink = baseURL =>
  baseURL ? `${baseURL}/settings/account` : ''

export const getDevelopersLink = baseURL =>
  baseURL ? `${baseURL}/developers/libraries` : ''

const OrgListItem = ({ organization, color, user, currentOrganization }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const name = organization?.name || ''
  const orgInitials = getOrgInitials(name)

  const handleClick = async () => {
    if (organization) {
      dispatch(resetTeamAppsList())
      const lastApp = await getOrganizationLastApp(organization.id, user.id)

      if (lastApp) {
        const appUrl = lastApp.id ? `/apps/${lastApp.id}` : '/first-app-setup'

        dispatch(setCurrentOrganization(organization.id))
        dispatch(fetchOrganization(organization.id))
        history.push(appUrl)
      }
    }
  }

  const selected =
    currentOrganization && currentOrganization.id === organization.id

  return (
    <div
      className={classNames('navbar-team-switcher-item', { selected })}
      onClick={handleClick}
    >
      <Profile color={color} profileText={orgInitials} />
      <div className="navbar-team-switcher-team-name">{name}</div>
    </div>
  )
}

export default PopulatedTeamSwitcher
