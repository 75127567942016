import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import './Dashboard.scss'
import Loading from '../Shared/Loading'

interface MultiValueChartProps {
  data: Array<{ date: string; [key: string]: number }>
  loading?: boolean
  title: string
  colors: Array<{ dataKey: string; color: string }>
  legendFormatter: (value: string) => string
  tooltipFormatter?: (
    value: number,
    name: string,
    props: {
      payload?: Array<{ value: number; name: string }>
      label?: string
      active?: boolean
    }
  ) => [string, string]
}

const MultiValueChart: React.FC<MultiValueChartProps> = ({
  data,
  loading,
  title,
  colors,
  legendFormatter,
  tooltipFormatter,
}) => {
  return (
    <div className="chart-container">
      <h2>{title}</h2>
      <p>Last 30 days</p>
      <ResponsiveContainer width="100%" height={200}>
        {loading ? (
          <div className="center-container">
            <Loading />
          </div>
        ) : (
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip formatter={tooltipFormatter} />
            <Legend formatter={legendFormatter} />
            {colors.map(color => (
              <Bar
                key={color.dataKey}
                dataKey={color.dataKey}
                fill={color.color}
                radius={[8, 8, 8, 8]}
              />
            ))}
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  )
}

export default MultiValueChart
