import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'

// utils
import { abbreviatedFormat } from 'utils/numbers'

// components
import Page from 'components/Shared/Page'
import Chip from 'components/Shared/Chip'
import Loading from 'components/Shared/Loading'
import Icon, { IconButton } from 'components/Shared/Icon'

// ducks
import {
  fetchLibraries,
  fetchLibraryByName,
  updateLibrary,
  updateLibraryVersion,
  selectLibraries,
} from 'ducks/admin/libraries'

import { DEBOUNCE_TIME } from '../../../constants'

import InfoModal from './Modal'

import '../Admin.scss'

// options for status selector
const options = [
  { label: 'pending', value: 'pending' },
  { label: 'testing', value: 'testing' },
  { label: 'approved', value: 'approved' },
  { label: 'rejected', value: 'rejected' },
  { label: 'active', value: 'active' },
  { label: 'private', value: 'private' },
  { label: 'unlisted', value: 'unlisted' },
  { label: 'deprecated', value: 'deprecated' },
]

const baseURL = process.env.REACT_APP_COMPONENT_URL

export function Libraries() {
  const dispatch = useDispatch()
  const libraries = useSelector(selectLibraries)
  const [search, setSearch] = useState('')

  useEffect(() => {
    dispatch(fetchLibraries())
  }, [])

  function handleChange(event) {
    setSearch(event.target.value)
    handleLibrariesQuery(event.target.value)
  }

  const handleLibrariesQuery = useCallback(
    debounce(value => dispatch(fetchLibraries(value)), DEBOUNCE_TIME),
    []
  )

  return (
    <Page className="admin-page" title="Marketplace Libraries">
      <section className="admin-page-header">
        <h1>Marketplace Libraries</h1>
        <div style={{ marginLeft: 'auto' }}>
          <input
            type="text"
            onChange={handleChange}
            value={search}
            placeholder="Search by name or author..."
            autoFocus
          />
        </div>
      </section>
      <div className="admin-page-table">
        <div className="admin-page-table-header">
          <p style={{ flex: 2 }}>Display Name</p>
          <p>Author</p>
          <p style={{ flex: 2 }}>Latest Version</p>
          <p>Public</p>
          <p>Needs Review</p>
          <p style={{ flex: 2 }}>Updated</p>
        </div>
        {libraries.status !== 'ready' ? (
          <div className="admin-page-table-loading">
            <Loading />
          </div>
        ) : libraries.data?.length > 0 ? (
          libraries.data.map(library => {
            const { id, name } = library
            const to = `/admin/marketplace/${name}`

            const latestVersion = library?.Versions?.[0]?.version
            const latestStatus = library?.Versions?.[0]?.status
            const needsReview = library?.Versions?.some(v => v.submitted)

            return (
              <Link key={id} className="admin-page-table-item" to={to}>
                <span style={{ flex: 2 }}>{library.displayName}</span>
                <span>{library.author}</span>
                <span style={{ flex: 2 }}>
                  <Chip
                    className={classNames('status', {
                      [`status-${latestStatus}`]: library?.public,
                    })}
                  >
                    <Chip.Content>
                      {library?.public
                        ? `${latestStatus}@${latestVersion}`
                        : latestVersion}
                    </Chip.Content>
                  </Chip>
                </span>
                <span>
                  {library.public ? (
                    <Icon type="check" />
                  ) : (
                    <Icon type="lock" />
                  )}
                </span>
                <span>{needsReview ? <Icon type="check" /> : null}</span>
                <span style={{ flex: 2 }}>
                  {moment(library.updatedAt).fromNow()}
                </span>
              </Link>
            )
          })
        ) : (
          <div className="admin-page-table-error">
            <h3>No Libraries Found</h3>
            <p>for "{search}"</p>
          </div>
        )}
      </div>
    </Page>
  )
}

export function Library(props) {
  const { match } = props
  let { libraryName, libraryAuthor } = match.params
  if (libraryAuthor) libraryName = `${libraryAuthor}/${libraryName}`

  const dispatch = useDispatch()
  const libraries = useSelector(selectLibraries)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  const currentLibrary = libraries.data.find(l => l.name === libraryName)

  useEffect(() => {
    if (!currentLibrary) dispatch(fetchLibraryByName(libraryName))
  }, [])

  function handleLibraryCheck(event) {
    const { id } = currentLibrary
    const { name } = event.target

    dispatch(
      updateLibrary(id, {
        [name]: !currentLibrary[name],
      })
    )
  }

  if (currentLibrary && currentLibrary.Versions) {
    currentLibrary.Versions.sort((a, b) => {
      // test if version follows semver
      const semver = /^\d+\.\d+\.\d+$/

      if (!semver.test(a.version)) {
        return -1
      } else if (!semver.test(b.version)) {
        return 1
      }

      const aVersion = a.version.split('.')
      const bVersion = b.version.split('.')

      if (aVersion[0] !== bVersion[0]) {
        return bVersion[0] - aVersion[0]
      } else if (aVersion[1] !== bVersion[1]) {
        return bVersion[1] - aVersion[1]
      } else {
        return bVersion[2] - aVersion[2]
      }
    })
  }

  return (
    <Page className="admin-page" title={libraryName}>
      <section className="admin-page-header">
        {currentLibrary?.logo ? (
          <img
            height="32"
            src={currentLibrary?.logo}
            alt="library logo"
            style={{ marginRight: 16 }}
          />
        ) : null}
        <h1>{libraryName}</h1>
        <span style={{ marginLeft: 'auto' }}>
          <IconButton type="info" onClick={() => setIsInfoModalOpen(true)} />
        </span>
        {currentLibrary?.public && currentLibrary?.listed ? (
          <Chip style={{ marginLeft: 16 }}>
            <Chip.Content>
              {abbreviatedFormat(currentLibrary?.installs || 0)} installs
            </Chip.Content>
          </Chip>
        ) : null}
      </section>
      <section className="admin-page-table">
        <div className="admin-page-table-header">
          <p>Author</p>
          <p>Email</p>
          <p>Public</p>
          <p>Listed</p>
        </div>
        {libraries.status !== 'ready' || !currentLibrary ? (
          <div className="admin-page-table-loading">
            <Loading />
          </div>
        ) : (
          <div className="admin-page-table-item">
            <span>{currentLibrary.User.name}</span>
            <span>{currentLibrary.User.email}</span>
            <span>
              <input
                type="checkbox"
                name="public"
                checked={currentLibrary.public}
                onChange={handleLibraryCheck}
              />
            </span>
            <span>
              <input
                type="checkbox"
                name="listed"
                checked={currentLibrary.listed}
                onChange={handleLibraryCheck}
              />
            </span>
          </div>
        )}
      </section>

      <section className="admin-page-table">
        <div className="admin-page-table-header">
          <p>Version</p>
          {currentLibrary?.public ? (
            <>
              <p>Status</p>
              <p>Active</p>
              <p>Needs Review</p>
            </>
          ) : null}
          <p>Submitted</p>
          <p>Download</p>
        </div>
        {libraries.status !== 'ready' || !currentLibrary ? (
          <div className="admin-page-table-loading">
            <Loading />
          </div>
        ) : (
          currentLibrary.Versions.map(version => {
            const { id } = currentLibrary
            const { id: versionId } = version

            function handleSelect(value) {
              dispatch(updateLibraryVersion(id, versionId, { status: value }))
            }

            function handleVersionCheck(event) {
              const { name } = event.target

              dispatch(
                updateLibraryVersion(id, versionId, { [name]: !version[name] })
              )
            }

            const downloadURL = `${baseURL}/${libraryName}/${version.version}.tar`

            return (
              <div key={version.id} className="admin-page-table-item">
                <span>
                  <strong>{version.version}</strong>
                </span>
                {currentLibrary?.public ? (
                  <>
                    <span>
                      <MultiMenuTrigger
                        menu={options}
                        onSelect={handleSelect}
                        rowHeight={32}
                      >
                        <Chip
                          className={classNames(
                            'status',
                            `status-${version.status}`
                          )}
                        >
                          <Chip.Content>{version.status}</Chip.Content>
                        </Chip>
                      </MultiMenuTrigger>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={version.active}
                        name="active"
                        onChange={handleVersionCheck}
                      />
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        checked={version.submitted}
                        name="submitted"
                        onChange={handleVersionCheck}
                      />
                    </span>
                  </>
                ) : null}
                <span title={moment(version.createdAt).format('LLL')}>
                  {moment(version.createdAt).fromNow()}
                </span>
                <span>
                  <IconButton type="download" href={downloadURL} />
                </span>
              </div>
            )
          })
        )}
      </section>
      {isInfoModalOpen ? (
        <InfoModal
          library={currentLibrary}
          closeModal={() => setIsInfoModalOpen(false)}
        />
      ) : null}
    </Page>
  )
}
