import React from 'react'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

interface PayingAccountsDbsChartProps {
  data: Array<{ name: string; value: number }>
  title: string
  colors: Array<string>
}

interface LabelProps {
  cx: number // Center x coordinate
  cy: number // Center y coordinate
  midAngle: number // Mid angle of the label
  innerRadius: number // Inner radius of the pie slice
  outerRadius: number // Outer radius of the pie slice
  percent: number // Value to be displayed in the label
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: LabelProps) => {
  const RADIAN = Math.PI / 180

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(3)}%`}
    </text>
  )
}

const PayingAccountsDbsChart: React.FC<PayingAccountsDbsChartProps> = ({
  data,
  title,
  colors,
}) => {
  const filteredData = data.filter(entry => entry.value > 0)

  return (
    <div className="chart-container">
      <h2>{title}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={filteredData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {filteredData.map(entry => {
              const dataKey = `cell-${entry.name.toLowerCase()}`
              const color = colors[filteredData.indexOf(entry) % colors.length]

              return <Cell key={dataKey} fill={color} />
            })}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PayingAccountsDbsChart
