import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getId } from '@adalo/utils'
import { dataTypes } from '@adalo/constants'

import { newAction } from 'utils/actions'
import { isRole } from 'utils/libraries'
import { getDatasourceType } from 'ducks/apps'
import { openAccordion } from 'ducks/accordions'
import {
  selectObject,
  getComponent,
  getCurrentAppId,
} from 'ducks/editor/objects'

import ActionItem from 'components/Editor/Actions/Item'
import ActionsEmptyState from 'components/Editor/Actions/EmptyState'
import Button from 'components/Shared/Button'
import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

class ActionRow extends Component {
  static defaultProps = {
    actionsKey: 'actions',
  }

  handleAdd = defaults => {
    const { name, onChange, openAccordion } = this.props
    const action = newAction(defaults)
    const actionId = getId()
    openAccordion('action-item', action.actions[0].id)

    onChange(
      { [name]: { type: dataTypes.ACTION_REF, actionId } },
      { [actionId]: action }
    )
  }

  handleUpdate = newValue => {
    const { value, onChange } = this.props

    if (!value || !newValue) {
      return
    }

    const { actionId } = value

    if (newValue.actions && newValue.actions.length === 0) {
      return this.handleRemove()
    }

    onChange(null, { [actionId]: newValue })
  }

  handleRemove = () => {
    const { name, value, onChange } = this.props

    if (!value) {
      return
    }

    onChange({ [name]: null }, { [value.actionId]: undefined })
  }

  render() {
    let {
      displayName,
      value,
      objectId,
      appId,
      componentId,
      object,
      datasourceType,
      role,
      reference,
      actionsKey,
      dummyActions,
      filterMenuOptions,
      omitContextualTables,
    } = this.props

    reference = isRole(role, 'listItem') && reference

    const tooltip = (
      <a
        href="https://help.adalo.com/action-basics"
        target="_blank"
        rel="noreferrer"
      >
        Learn more.
      </a>
    )

    if (!value && !(dummyActions && dummyActions.length > 0)) {
      return (
        <div className="library-inspect-action">
          <div className="action-row-header">
            <p className="library-inspect-action-title">{displayName}</p>
            <Tooltip tooltip={tooltip} hideArrow small>
              <Icon type="help-small" />
            </Tooltip>
          </div>
          <ActionsEmptyState
            onCreate={this.handleAdd}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
            datasourceType={datasourceType}
            reference={reference}
          >
            <Button type="button" icon="plus-small" listAddButton gray>
              Add Action
            </Button>
          </ActionsEmptyState>
        </div>
      )
    }

    const chainDummyAction = (dummyActions?.length ?? 0) > 0 ? dummyActions : undefined // prettier-ignore

    return (
      <div className="library-inspect-action">
        <div className="action-row-header">
          <p className="library-inspect-action-title">{displayName}</p>
          <Tooltip tooltip={tooltip} hideArrow small>
            <Icon type="help-small" />
          </Tooltip>
        </div>
        <ActionChain
          dummyActions={chainDummyAction}
          filterMenuOptions={filterMenuOptions}
          omitContextualTables={omitContextualTables}
          actionId={value && value.actionId}
          objectId={objectId}
          appId={appId}
          object={object}
          componentId={componentId}
          datasourceType={datasourceType}
          onCreateFirst={this.handleAdd}
          onUpdate={this.handleUpdate}
          onRemove={this.handleRemove}
          reference={reference}
          actionsKey={actionsKey}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, { objectId }) => {
  const appId = getCurrentAppId(state)

  return {
    appId,
    componentId: getComponent(state, objectId)?.id,
    object: selectObject(state, objectId),
    datasourceType: getDatasourceType(state, appId),
  }
}

export default connect(mapStateToProps, { openAccordion })(ActionRow)

class ActionChain extends Component {
  getAction = () => {
    const { object, actionId, actionsKey } = this.props

    return object?.[actionsKey]?.[actionId]
  }

  render() {
    const {
      appId,
      componentId,
      objectId,
      actionId,
      datasourceType,
      onUpdate,
      onRemove,
      reference,
      dummyActions,
      onCreateFirst,
      filterMenuOptions,
      omitContextualTables,
    } = this.props

    return (
      <ActionItem
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        id={actionId}
        action={this.getAction()}
        datasourceType={datasourceType}
        onUpdate={onUpdate}
        onRemove={onRemove}
        reference={reference}
        dummyActions={dummyActions}
        onCreateFirst={onCreateFirst}
        filterMenuOptions={filterMenuOptions}
        omitContextualTables={omitContextualTables}
      />
    )
  }
}
