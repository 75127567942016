import { useState, useEffect, ReactElement } from 'react'
import Page from '../Shared/Page'
import { adaloBackendAxios } from '../../utils/io/http/axios'
import './Dashboard.scss'
import Chart from './Chart'
import MultiValueChart from './MultiValueChart'
import PayingAccountsDbsChart from './PayingAccountsDbsChart'

interface Stats {
  apps_count: number
  organizations_count: number
  users_count: number
}

interface ChartData {
  date: string
  count: number
}

interface UsersResponse {
  date: string
  total_count: number
  confirmed_count: number
}

interface PayingAccountsDbsResponse {
  name: string
  value: number
}

interface DbClusterStatResponse {
  host: string
  db_count: number
}

const Dashboard = (): ReactElement => {
  const [stats, setStats] = useState<Stats | null>(null)
  const [dbClusterStats, setDbClusterStats] = useState<
    DbClusterStatResponse[] | null
  >(null)
  const [apps, setApps] = useState<ChartData[] | null>(null)
  const [users, setUsers] = useState<UsersResponse[] | null>(null)
  const [organizations, setOrganizations] = useState<ChartData[] | null>(null)
  const [datasources, setDatasources] = useState<ChartData[] | null>(null)
  const [payingAccountsDbs, setPayingAccountsDbs] = useState<
    PayingAccountsDbsResponse[]
  >([])

  const fetchMetric = async <T,>(
    endpoint: string,
    setter: (data: T) => void
  ) => {
    try {
      const { data } = await adaloBackendAxios.get<T>(endpoint)
      setter(data)
    } catch (error) {
      console.error(`Failed to fetch data from ${endpoint}:`, error)
    }
  }

  /* eslint-disable @typescript-eslint/no-floating-promises */
  const fetchAllData = () => {
    fetchMetric('/admin/dashboard/stats', setStats)
    fetchMetric('/admin/dashboard/db-cluster-stats', setDbClusterStats)
    fetchMetric('/admin/dashboard/apps', setApps)
    fetchMetric('/admin/dashboard/users', setUsers)
    fetchMetric('/admin/dashboard/organizations', setOrganizations)
    fetchMetric('/admin/dashboard/datasources', setDatasources)
    fetchMetric('/admin/dashboard/paying-accounts-dbs', setPayingAccountsDbs)
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  return (
    <Page className="admin-dashboard-page" title="Dashboard">
      <div className="stats-container">
        <div>
          <p>Apps</p>
          <p>{stats?.apps_count || 0}</p>
        </div>
        <div>
          <p>Organizations</p>
          <p>{stats?.organizations_count || 0}</p>
        </div>
        <div>
          <p>Users</p>
          <p>{stats?.users_count || 0}</p>
        </div>
      </div>
      <div className="charts-container">
        <PayingAccountsDbsChart
          data={payingAccountsDbs}
          title="Paying Accounts Dbs"
          colors={['#FFBB28', '#0088FE']}
        />
        <Chart
          title="Cluster Databases"
          data={(dbClusterStats || []).map(item => ({
            name: item.host,
            count: item.db_count,
          }))}
          xKey="name"
          yKey="count"
          barColor="#82ca9d"
        />
      </div>
      <div className="charts-container">
        <Chart
          title="New Daily Apps"
          desc="Last 30 days"
          data={apps}
          barColor="#ea6c57"
        />
        <Chart
          title="New Daily Organizations"
          desc="Last 30 days"
          data={organizations}
          barColor="#269d91"
        />
      </div>
      <div className="charts-container">
        <MultiValueChart
          title="New Daily Users"
          data={users}
          colors={[
            { dataKey: 'total_count', color: '#ea6c57' },
            { dataKey: 'confirmed_count', color: '#269d91' },
          ]}
          legendFormatter={value => {
            const labels: Record<string, string> = {
              total_count: 'Total',
              confirmed_count: 'Verified',
            }

            return labels[value] || value
          }}
          tooltipFormatter={(value, name) => {
            const labels: Record<string, string> = {
              total_count: 'Total',
              confirmed_count: 'Verified',
            }

            return [value.toString(), labels[name] || name]
          }}
        />
      </div>
      <div className="charts-container">
        <Chart
          title="New Daily Datasources"
          desc="Last 30 days"
          data={datasources}
          barColor="#f3a361"
        />
      </div>
    </Page>
  )
}

export default Dashboard
