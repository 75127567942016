import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

// components
import Modal from 'components/Shared/Modal'
import { PRICE_TYPE_YEARLY, isLegacyPlanType } from 'utils/billing.ts'

import PlanOptionsBoard from './PlanOptionsBoard'

import {
  PUBLISHED_APPS_STEP,
  PlanChangeContext,
} from '../TrialWarning/PlanChangeContext'

import './NewPlanSelectModal.scss'
import Button from '../Button'

const PLAN_FREE = 'free'

const NewPlanSelectModal = ({
  onCancel,
  onDowngrade,
  annual,
  setAnnualFlag,
  setPlanSelectedFlag,
  subscription = {},
  setSelectedPlanValue,
  isOrganizationAdmin,
  organizationId,
  organizationName,
}) => {
  const { setCurrentStep } = useContext(PlanChangeContext)
  const { planType } = subscription
  const isOnLegacyPlan = isLegacyPlanType(planType)
  const history = useHistory()

  const title = isOnLegacyPlan
    ? 'Switch Plans to Unlock Full Potential'
    : 'Upgrade to Unlock Full Potential'

  const onPlanSelected = (selectedPlan, interval) => {
    if (selectedPlan === PLAN_FREE) {
      return onDowngrade()
    }

    const annual = interval === PRICE_TYPE_YEARLY

    setAnnualFlag(annual)
    setPlanSelectedFlag(true)
    setSelectedPlanValue(selectedPlan)
    setCurrentStep(PUBLISHED_APPS_STEP)
  }

  const openMaintenancePlanPage = () => {
    history.push({
      pathname: `/organizations/${organizationId}/subscribe-for-maintenance`,
    })
  }

  return (
    <div className="NewPlanSelectModal">
      <Modal.Header
        title={title}
        content={() => (
          <>
            {planType === 'free' && (
              <Button small onClick={openMaintenancePlanPage}>
                Upgrade To Maintenance Mode
              </Button>
            )}
            <Modal.Button
              to="https://adalo.com/pricing"
              iconSide="right"
              iconSize="small"
              target="_blank"
              icon="open-in-new"
              placement="right"
              teal
              text
            >
              Learn more
            </Modal.Button>
          </>
        )}
      />
      <Modal.Content className="NewPlanSelectModal-content">
        <PlanOptionsBoard
          subscription={subscription}
          defaultAnnual={annual}
          isOrganizationAdmin={isOrganizationAdmin}
          organizationName={organizationName}
          onPlanSelect={onPlanSelected}
        />
      </Modal.Content>

      <Modal.Actions>
        <Modal.Button type="button" text onClick={onCancel}>
          Cancel
        </Modal.Button>
      </Modal.Actions>
    </div>
  )
}

export default NewPlanSelectModal
