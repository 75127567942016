import React from 'react'
import { formatDate } from 'utils/datetime'

import Button from '../Button'

import '../../Settings/Billing/Billing.scss'

import logo from '../Icon/icons/money-mouth-face.png'

function getSummaryPlanName(planType) {
  if (planType === 'business' || planType === 'launch') {
    return 'Business Plan'
  }

  return 'Pro Plan'
}

function PreviousBilling({ prevBillAmount, prevBillDate }) {
  if (prevBillAmount === '0.00') {
    return null
  }

  return (
    <p className="secondary large">
      {`Your last bill for $${prevBillAmount}
          was paid on ${formatDate(prevBillDate)}.`}
    </p>
  )
}

function NextBilling({
  setToCancel,
  planAmount,
  planInterval,
  nextBillAmount,
  nextBillDate,
  cardNumber,
}) {
  if (setToCancel) {
    return (
      <p className="secondary large">{`Your ${planInterval}ly plan will be cancelled on ${formatDate(
        nextBillDate
      )}. You will not be charged again`}</p>
    )
  }

  if (nextBillAmount <= 0) {
    return (
      <p className="secondary large">{`Your ${planInterval}ly plan renews on ${formatDate(
        nextBillDate
      )}.
            Adjusted for your account balance, you will not be charged.`}</p>
    )
  }

  if (nextBillAmount === planAmount) {
    return (
      <p className="secondary large">{`Your ${planInterval}ly plan renews on ${formatDate(
        nextBillDate
      )},
      and the card •••• ${cardNumber} will be
          automatically charged.`}</p>
    )
  }

  return (
    <p className="secondary large">{`Your ${planInterval}ly plan renews on ${formatDate(
      nextBillDate
    )}.
    Adjusted for your account balance, the card •••• ${cardNumber} will be
        automatically charged $${nextBillAmount}`}</p>
  )
}

function YearlyPromo({ planInterval, planType, onSwitchToYearly }) {
  return (
    <div className="team-settings-annual-plan-promo">
      <div className="new-plan-pricing">
        <img src={logo} className="money-mouth-image" alt="" />
        <h2> New Yearly Pricing </h2>
      </div>
      {planType === 'pro' || planType === 'individual' ? (
        <p>Save 20% by switching to Pro Plan Yearly (That's a $132 savings!)</p>
      ) : (
        <p>
          Save 20% by switching to Business Plan Yearly (That's a $612 savings!)
        </p>
      )}
      <Button onClick={onSwitchToYearly}>SWITCH TO YEARLY</Button>
    </div>
  )
}

function SubscriptionStatus({ subscription }) {
  const {
    prevBillAmount,
    nextBillAmount,
    nextBillDate,
    prevBillDate,
    cardNumber,
    planInterval,
    planAmount,
    setToCancel,
  } = subscription

  return (
    <>
      <PreviousBilling
        prevBillAmount={prevBillAmount}
        prevBillDate={prevBillDate}
      />
      <NextBilling
        setToCancel={setToCancel}
        planAmount={planAmount}
        planInterval={planInterval}
        nextBillAmount={nextBillAmount}
        nextBillDate={nextBillDate}
        cardNumber={cardNumber}
      />
    </>
  )
}

function BillingSummary({
  organization,
  subscription,
  onShowPaymentSettings,
  onToggleForm,
  onCustomerPortalRedirect,
  onSwitchToYearly,
}) {
  const { planType } = organization || {}

  const { planInterval } = subscription || {}

  const hasSubscription = subscription && subscription.configured
  const isMaintenancePlan = planType === 'maintenance'
  const showYearlyPromo =
    hasSubscription && planInterval !== 'year' && !isMaintenancePlan

  return (
    <div className="team-settings-billing-summary">
      <h2>
        <div>{getSummaryPlanName(planType)}</div>
      </h2>
      {hasSubscription ? (
        <SubscriptionStatus subscription={subscription} />
      ) : null}
      <div className="team-settings-billing-buttons">
        <Button small outlined teal onClick={onShowPaymentSettings}>
          Update Payment Settings
        </Button>
        <Button
          small
          outlined
          teal
          onClick={onToggleForm}
          data-adalo-id="legacy-change-plan-billing"
        >
          Change Plan
        </Button>
        {hasSubscription ? (
          <Button small outlined teal onClick={onCustomerPortalRedirect}>
            View Invoices
          </Button>
        ) : null}
      </div>
      {showYearlyPromo ? (
        <YearlyPromo
          planInterval={planInterval}
          planType={planType}
          onSwitchToYearly={onSwitchToYearly}
        />
      ) : null}
    </div>
  )
}

export default BillingSummary
