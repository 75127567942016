import React from 'react'

import { THEMES } from 'constants.js'

import ListFilters from '../Libraries/ListFilters'
import MenuControl from '../Libraries/MenuControl'

export const FilterControl = ({
  filterOptions,
  value,
  getLabel,
  handleChangeFilter,
  filterComparator,
  handleChangeSecondaryFilter,
  object,
  appId,
  componentId,
}) => {
  const tooltip = (
    <a
      href="https://help.adalo.com/component-basics/conditions-and-filtering"
      target="_blank"
      rel="noreferrer"
    >
      Learn more.
    </a>
  )

  return (
    <>
      <MenuControl
        options={filterOptions}
        name="filter"
        displayName="Filter"
        value={value}
        getLabel={getLabel}
        onChange={handleChangeFilter}
        comparator={filterComparator}
        menuTheme={THEMES.DATA}
        tooltip={tooltip}
      />
      <ListFilters
        onChange={handleChangeSecondaryFilter}
        binding={value}
        object={object}
        appId={appId}
        componentId={componentId}
      />
    </>
  )
}
