import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  LABEL,
  LINE,
  SECTION,
  ELLIPSE,
  SHAPE,
  IMAGE,
  LIST,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  SELECT,
  FORM,
  WEB_VIEW,
  LOCATION_INPUT,
  LIBRARY_COMPONENT,
  VIDEO,
  TABLE,
} from '@adalo/constants'
import { findIndex, uniq } from 'lodash'
import { traverse } from '@adalo/utils'

import {
  getLibraryMenu,
  getComponentIcon,
  addComponentToCategory,
  removeComponentFromCategory,
  getDefaultProps,
} from 'utils/libraries'
import { defaults } from 'utils/objects'
import { getLocalLibraries } from 'utils/developers'

import { setTool } from 'ducks/editor/tools'
import { getApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users/index.ts'
import {
  getInstalledLibraries,
  getPrivateLibraries,
  getAdminLibraries,
  getTopComponents,
} from 'ducks/marketplace'
import { setLayersHover, setSelection } from 'ducks/editor/selection'
import { getLicenses } from 'ducks/marketplace/licenses'
import { getFeatureFlag } from 'ducks/featureFlags'

import { ADD_DRAGGABLE_OPTIONS } from 'components/Editor/Canvas/AddDraggableComponent'
import List from 'components/Editor/Canvas/List'
import { ComponentItem } from 'components/Editor/ComponentsAccordion'
import CalloutCard from 'components/Shared/CalloutCard'
import Icon from 'components/Shared/Icon'

import Tab from '../Tab'
import MarketplaceHeaderImage from './marketplace-empty.png'
import { componentBlocklist } from '../ResponsiveAddMenu/componentBlocklist'

export const getClassicComponents = ({
  hasVideoComponent,
  hasTableComponent,
}) => ({
  rectangle: {
    icon: 'section',
    label: 'Rectangle',
    value: {
      type: SECTION,
      options: defaults[SECTION],
    },
  },
  line: {
    icon: 'line',
    label: 'Line',
    value: {
      type: LINE,
      options: defaults[LINE],
    },
  },
  ellipse: {
    icon: 'ellipse',
    label: 'Ellipse',
    value: {
      type: ELLIPSE,
      options: defaults[ELLIPSE],
    },
  },
  text: {
    icon: 'text',
    label: 'Text',
    value: {
      type: LABEL,
      options: {
        ...defaults[LABEL],
        [ADD_DRAGGABLE_OPTIONS]: {
          xOffset: 124,
        },
      },
    },
  },
  image: {
    icon: 'image',
    label: 'Image',
    value: {
      type: IMAGE,
      options: defaults[IMAGE],
    },
  },
  vector: {
    icon: 'pen',
    label: 'Vector',
    value: SHAPE,
  },
  list: {
    icon: 'list',
    label: 'Custom List',
    value: {
      type: LIST,
      options: {
        ...defaults[LIST],
        children: List.defaultChildren,
      },
    },
  },
  form: {
    icon: 'form',
    label: 'Form',
    value: {
      type: FORM,
      options: defaults[FORM],
    },
    searchTerms: 'checkbox radio button select input',
  },
  textInput: {
    icon: 'text-input',
    label: 'Text Input',
    value: {
      type: INPUT,
      options: defaults[INPUT],
    },
    searchTerms: 'text area',
  },
  imageUpload: {
    icon: 'image-upload',
    label: 'Image Picker',
    value: {
      type: IMAGE_UPLOAD,
      options: defaults[IMAGE_UPLOAD],
    },
  },
  fileUpload: {
    icon: 'file-upload',
    label: 'File Picker',
    value: {
      type: FILE_UPLOAD,
      options: defaults[FILE_UPLOAD],
    },
  },
  datePicker: {
    icon: 'date-picker',
    label: 'Date Picker',
    value: {
      type: DATE_PICKER,
      options: defaults[DATE_PICKER],
    },
  },
  dropdownMenu: {
    icon: 'select',
    label: 'Dropdown Menu',
    value: {
      type: SELECT,
      options: defaults[SELECT],
    },
    searchTerms: 'select',
  },
  webView: {
    icon: 'webview',
    label: 'Web View',
    value: {
      type: WEB_VIEW,
      options: defaults[WEB_VIEW],
    },
  },
  locationField: {
    icon: 'location-input',
    label: 'Location Input',
    value: {
      type: LOCATION_INPUT,
      options: defaults[LOCATION_INPUT],
    },
  },
  ...(hasVideoComponent && {
    [VIDEO]: {
      icon: 'video',
      label: 'Video',
      value: {
        type: VIDEO,
        options: defaults[VIDEO],
      },
    },
  }),
  ...(hasTableComponent && {
    [TABLE]: {
      icon: 'table',
      label: 'Table',
      value: {
        type: TABLE,
        options: defaults[TABLE],
      },
    },
  }),
})

export const libComponentRef = (library, component, libraryOpts) => ({
  $ref: { library, component, libraryOpts },
})

// TODO: convert array of libraryOptions to use library object instead
export function resolveRef($ref, libraryOptions) {
  const library = libraryOptions.find(x => {
    return x.library === $ref.library || x.label === $ref.library
  })

  if (!library) return null

  const component = library.children.find(x => {
    return x.name === $ref.component || x.label === $ref.component
  })

  if (!component) return null

  Object.assign(component, $ref.libraryOpts)

  if (component?.label === 'Stripe ACH Payment') return null

  if (component?.label === 'Toggle') {
    component.searchTerms = 'checkbox check, box radio button'
  }

  if (component?.value?.options) {
    const {
      value: { options },
    } = component

    const { libraryName, libraryVersion, componentName } = options

    return {
      ...component,
      id: `${libraryName}${libraryVersion}${componentName}`,
    }
  }

  return component
}

export function getIconStyles(value) {
  if (value?.options) {
    const { libraryName, libraryVersion, componentName } = value.options
    if (!libraryName) return null

    const iconURL = getComponentIcon(libraryName, libraryVersion, componentName)

    return {
      backgroundImage: `url(${iconURL})`,
    }
  }
}

const getComponentObject = (name, manifest, library, opts) => {
  const { displayName, icon } = manifest
  const { id: libraryId, name: libraryName, price, version } = library
  const { installed } = opts

  const defaultProps = getDefaultProps(manifest)

  return {
    library: libraryName,
    displayName,
    name,
    icon,
    label: displayName,
    id: `${libraryName}${version}${name}`,
    key: `${libraryName}${version}${name}`,
    value: {
      // Mimic "getLibraryComponentOptions" from "utils/libraries.js
      type: LIBRARY_COMPONENT,
      options: {
        ...defaultProps,
        libraryName,
        libraryVersion: version,
        componentName: name,
        width: manifest.defaultWidth,
        height: manifest.defaultHeight,
        snappingRules: manifest.snappingRules,
      },
    },
    version: `${version}`,
    installed,
    price,
    showView: !installed,
    libraryId,
  }
}

export const getMarketplaceLibraries = (
  libraries,
  licenses = [],
  withLibraryOpts = false
) => {
  const availableLibraries = window?.protonLibraries
  const marketplaceLibraries = []
  const addedLibraries = new Set()

  // Ensure we are using metadata from database ('componentsManifest') if available, fallback to the library packaged in S3.
  // This is to ensure that we are using the latest metadata for the component. 'availableLibraries' should be deprecated in the future.
  for (const library of libraries) {
    if (!library || !library.componentsManifest) continue

    const { name, componentsManifest } = library

    const installed =
      Array.isArray(licenses) &&
      licenses?.find(license => license.name === name)

    for (const [component, manifest] of Object.entries(componentsManifest)) {
      const componentBlocked = componentBlocklist[name]?.includes(component)

      // Check for props in manifest to ensure it is a valid component
      if (!component || componentBlocked || !Array.isArray(manifest.props)) {
        continue
      }

      const obj = getComponentObject(component, manifest, library, {
        installed,
      })

      addedLibraries.add(name)
      marketplaceLibraries.push(obj)
    }
  }

  if (availableLibraries) {
    for (const library of libraries) {
      if (!library || addedLibraries.has(library?.name)) continue

      const { id, name, version, Versions, price, showView } = library

      // marketplace listings can come back with multiple versions of the same library
      const libraryVersions = new Set(
        [version].concat(Versions?.map(v => v.version).filter(Boolean))
      )

      const targetLibVersions = availableLibraries[name]
      let targetLib
      if (targetLibVersions) {
        const availableVersion = Object.keys(targetLibVersions)
          .sort((a, b) => (a < b ? 1 : -1))
          .find(version => libraryVersions.has(version))
        targetLib = targetLibVersions?.[availableVersion]?.config
      }

      // validate that library is available
      if (!targetLib) {
        if (!withLibraryOpts) {
          continue
        } else {
          const { name: libraryName, components, version, price } = library

          for (const component of components) {
            let componentName = component

            if (typeof component !== 'string') {
              componentName = component.name
            }

            if (componentBlocklist[libraryName]?.includes(componentName)) {
              continue
            }

            const object = {
              library: libraryName,
              displayName: library.displayName,
              name: componentName,
              icon: library.icon,
              label: componentName.replace(/([a-z])([A-Z])/g, '$1 $2'),
              id: `${libraryName}${version}${componentName}`,
              key: `${libraryName}${version}${componentName}`,
              value: {
                type: LIBRARY_COMPONENT,
                options: {
                  ...defaults[LIBRARY_COMPONENT],
                  libraryName,
                  libraryVersion: version,
                  componentName,
                },
              },
              version: `${version}`,
              price: price ?? 0,
              installed: false,
              showView: true,
              libraryId: library.id,
            }

            marketplaceLibraries.push(object)
          }
        }
      } else {
        const { name: libraryName, components } = targetLib

        for (const component of components) {
          if (!component) continue
          const { displayName } = component

          const libraryOpts = {}

          if (withLibraryOpts) {
            if (typeof price === 'number') libraryOpts.price = price
            libraryOpts.installed = true
            if (typeof showView === 'boolean') libraryOpts.showView = showView
            libraryOpts.libraryId = id
          }

          const ref = libComponentRef(libraryName, displayName, libraryOpts)

          marketplaceLibraries.push(ref)
        }
      }
    }
  }

  return marketplaceLibraries
}

export const handleChildren = (app, children) => {
  const libraryOptions = getLibraryMenu(app)

  return children
    .map(component => {
      if (component?.$ref) return resolveRef(component.$ref, libraryOptions)

      return component
    })
    .filter(Boolean)
}

const sideNavigationComponent = libComponentRef('Navigation', 'Side Navigation')
const topNavigationComponent = libComponentRef('Navigation', 'Top Navigation')

const getCategorized = ({ hasVideoComponent, hasTableComponent }) => {
  const classicComponents = getClassicComponents({
    hasVideoComponent,
    hasTableComponent,
  })

  return [
    {
      title: 'Most Used',
      components: [
        classicComponents.text,
        libComponentRef('Material Design', 'Button'),
        libComponentRef('Material Design', 'Simple List'),
        libComponentRef('Material Design', 'App Bar'),
        classicComponents.image,
        classicComponents.form,
      ],
    },
    {
      title: 'Navigation',
      components: [
        sideNavigationComponent,
        topNavigationComponent,
        libComponentRef('Material Design', 'Bottom Navigation'),
        libComponentRef('Material Design', 'App Bar'),
      ],
    },
    {
      title: 'Lists',
      components: [
        libComponentRef('Material Design', 'Simple List'),
        libComponentRef('Material Design', 'Card List'),
        libComponentRef('Material Design', 'Image List'),
        libComponentRef('Material Design', 'Avatar List'),
        libComponentRef('Material Design', 'Horizontal Card List'),
        libComponentRef('Material Design', 'Horizontal Chip List'),
        classicComponents.list,
        ...(hasTableComponent ? [classicComponents.table] : []),
      ],
    },
    {
      title: 'Buttons',
      components: [
        libComponentRef('Material Design', 'Button'),
        libComponentRef('Material Design', 'Action Button'),
        libComponentRef('Material Design', 'Icon'),
        libComponentRef('Material Design', 'Toggle'),
      ],
    },
    {
      title: 'Simple',
      components: [
        classicComponents.text,
        classicComponents.image,
        ...(hasVideoComponent ? [classicComponents.video] : []),
        classicComponents.ellipse,
        classicComponents.rectangle,
        classicComponents.line,
        classicComponents.vector,
        classicComponents.webView,
      ],
    },
    {
      title: 'Forms & Fields',
      components: [
        classicComponents.form,
        classicComponents.textInput,
        classicComponents.datePicker,
        classicComponents.dropdownMenu,
        classicComponents.fileUpload,
        classicComponents.imageUpload,
        classicComponents.locationField,
      ],
    },
  ]
}

const ComponentsTab = ({
  setSelection,
  history,
  match,
  options,
  adaloAdmin,
  app,
  setTool,
  isResponsiveApp,
  hasNewAddMenuWithSections,
  hasTableComponent,
  licenses = [],
  ...props
}) => {
  const { appId } = match.params

  const renderItem = component => {
    const {
      id,
      label,
      value,
      icon,
      screenIds = [],
      componentIds = [],
      type,
      price,
      installed,
      showView,
      libraryId,
    } = component

    const isComponentView = screenIds.length

    if (isComponentView) {
      const componentView = { name: label, screenIds, type }
      let iconImage = null

      if (icon) {
        const { libraryName, libraryVersion, componentName } = value.options
        const iconURL = getComponentIcon(
          libraryName,
          libraryVersion,
          componentName
        )

        iconImage = (
          <img
            src={iconURL}
            alt={`${label}-icon`}
            className="editor-view-component-thumbnail"
          />
        )
      }

      return (
        <div
          className="editor-view-components"
          key={id || label}
          onClick={() =>
            setSelection(componentIds, undefined, false, componentView)
          }
        >
          {iconImage}
          <p className="editor-view-component-title" title={label}>
            {label}
          </p>
          <Icon
            small
            type={type === 'Marketplace' ? 'marketplace' : 'lock-small'}
            title={type}
          />
          <Icon type="expand" />
        </div>
      )
    }

    return (
      <ComponentItem
        key={id || label}
        name={label}
        iconClassName={icon && `editor-add-component-icon-${icon}`}
        iconStyles={getIconStyles(value)}
        price={price}
        installed={installed}
        showView={showView}
        onMouseDown={e => {
          if (!showView) {
            handleSelect(value, [e.clientX, e.clientY])
          }
        }}
        onViewClick={() => {
          history.push(`/apps/${appId}/marketplace/${libraryId}`)
        }}
      />
    )
  }

  const handleSelect = async (type, startPosition) => {
    if (!type) {
      return
    }

    let options

    if (typeof type === 'object') {
      options = type.options
      type = type.type
    } else {
      options = {}
    }

    history.push(`/apps/${appId}/screens`)
    setTool(type, options, startPosition)
  }

  const getDevelopmentLibraries = () => {
    const localLibraries = getLocalLibraries()
    const availableLibraries = window?.protonLibraries

    const developmentLibraries = []

    if (localLibraries.length > 0) {
      localLibraries.forEach(localLibrary => {
        const library = availableLibraries?.[localLibrary]

        if (library?.dev?.config) {
          const { name: libraryName, components } = library.dev.config

          components.map(component => {
            const { displayName } = component
            const ref = libComponentRef(libraryName, displayName)

            return developmentLibraries.push(ref)
          })
        }
      })
    }

    return developmentLibraries
  }

  const getMarketplaceOptions = () => {
    const {
      installedLibraries,
      privateLibraries,
      adminLibraries,
      topComponents,
    } = props

    const developmentLibraries = getDevelopmentLibraries()

    const categories = []

    // show only the callout card if there are no libraries
    if (
      (!Array.isArray(installedLibraries) || !installedLibraries.length) &&
      (!Array.isArray(privateLibraries) || !privateLibraries.length) &&
      (!Array.isArray(adminLibraries) || !adminLibraries.length) &&
      (!Array.isArray(developmentLibraries) || !developmentLibraries.length) &&
      (!hasNewAddMenuWithSections || !Array.isArray(topComponents) || !topComponents.length) // prettier-ignore
    ) {
      return categories
    }

    const topComponentsLabel = 'Top Marketplace Components'

    if (
      hasNewAddMenuWithSections &&
      !categories.find(c => c.label === topComponentsLabel)
    ) {
      categories.push({ label: topComponentsLabel, children: [] })
    }

    if (hasNewAddMenuWithSections && topComponents.length > 0) {
      const topComponentsWithStatus = topComponents.map(topComponent => {
        const installed = installedLibraries.find(
          library => library.name === topComponent.name
        )

        return {
          ...topComponent,
          installed: !!installed,
          showView: !installed,
        }
      })

      const topIndex = findIndex(categories, { label: topComponentsLabel })
      const topComponentsCategory = categories?.[topIndex]

      const withLibraryOpts = topComponentsWithStatus.length > 0

      const topComponentsConfigs = getMarketplaceLibraries(
        topComponentsWithStatus,
        licenses,
        withLibraryOpts
      )

      const filteredTopComponents = topComponentsConfigs.filter(
        ({ $ref, library: libraryName, name: componentName }) => {
          if ($ref) {
            const { library, component: name } = $ref

            return library === '@adalo/stripe-kit'
              ? name === 'Stripe Payment'
              : library
          }

          return libraryName === '@adalo/stripe-kit'
            ? componentName === 'Stripe'
            : libraryName
        }
      )

      topComponentsCategory.children = handleChildren(
        app,
        filteredTopComponents
      )
    }

    /// add category "Installed" to marketplace section
    const installedMarketplaceLibraries = getMarketplaceLibraries(
      installedLibraries,
      licenses
    )

    let installedLabel = 'Installed'

    if (hasNewAddMenuWithSections) {
      installedLabel = 'Installed Marketplace Components'
    }

    if (adaloAdmin) {
      installedLabel = 'Installed In Team'
    }

    if (!categories.find(c => c.label === installedLabel)) {
      categories.push({ label: installedLabel, children: [] })
    }

    if (installedMarketplaceLibraries.length > 0) {
      const installedIndex = findIndex(categories, { label: installedLabel })
      const installedLibs = categories?.[installedIndex]

      // add any new libraries to children
      installedLibs.children = handleChildren(
        app,
        installedMarketplaceLibraries
      )
    }

    /// add category "Private" to marketplace section
    const privateMarketplaceLibraries = getMarketplaceLibraries(
      privateLibraries,
      licenses
    )

    const privateLabel = hasNewAddMenuWithSections
      ? 'Private Components'
      : 'Private'

    if (!categories.find(c => c.label === privateLabel)) {
      const title = 'No Private Components'

      const body =
        'Develop your own private component or \nadd one to the marketplace!'

      const button = {
        icon: 'code',
        children: 'Create Component',
        to: 'https://developers.adalo.com',
        target: '_blank',
        rel: 'noreferrer noopener',
        outlined: true,
      }

      const calloutProps = {
        title,
        body,
        color: 'grey',
        borderStyle: 'dashed',
        button,
      }

      const emptyCallout = <CalloutCard {...calloutProps} />
      categories.push({ label: privateLabel, children: [], emptyCallout })
    }

    if (privateMarketplaceLibraries.length > 0) {
      const privateIndex = findIndex(categories, { label: privateLabel })
      const privateLibs = categories?.[privateIndex]

      // add libraries to children
      privateLibs.children = handleChildren(app, privateMarketplaceLibraries)
    }

    const adminLabel = 'Under Review'

    const adminMarketplaceLibraries = getMarketplaceLibraries(
      adminLibraries,
      licenses
    )

    if (adminMarketplaceLibraries.length > 0) {
      if (!categories.find(c => c.label === adminLabel)) {
        categories.push({ label: adminLabel, children: [] })
      }

      const adminIndex = findIndex(categories, { label: adminLabel })
      const adminLibs = categories?.[adminIndex]

      adminLibs.children = handleChildren(app, adminMarketplaceLibraries)
    }

    const developmentLabel = 'Development'

    if (developmentLibraries?.length > 0) {
      if (!categories.find(c => c.label === developmentLabel)) {
        categories.push({ label: developmentLabel, children: [] })
      }

      const developmentIndex = findIndex(categories, {
        label: developmentLabel,
      })

      const developmentLibs = categories?.[developmentIndex]

      // add any new libraries to children
      developmentLibs.children = handleChildren(app, developmentLibraries)
    }

    return categories
  }

  const getUsedComponents = (components, type) => {
    const { components: screens = [] } = app || {}
    const usedComponents = {}

    for (const [screenId, screen] of Object.entries(screens)) {
      traverse(
        screen.objects,
        obj => {
          if (!obj.libraryName) {
            return
          }

          const component = components.find(
            library => library.library === obj.libraryName
          )

          if (!component) {
            return
          }

          if (usedComponents[component.name]) {
            usedComponents[component.name] = {
              ...usedComponents[component.name],
              screenIds: uniq([
                ...usedComponents[component.name].screenIds,
                screenId,
              ]),
              componentIds: [
                ...usedComponents[component.name].componentIds,
                obj.id,
              ],
            }
          } else {
            usedComponents[component.name] = {
              ...component,
              type,
              screenIds: [screenId],
              componentIds: [obj.id],
            }
          }
        },
        obj => Array.isArray(obj.children)
      )
    }

    return Object.values(usedComponents)
  }

  const getComponentsByScreen = () => {
    const { installedLibraries, privateLibraries } = props

    const marketplaceComponents = handleChildren(
      app,
      getMarketplaceLibraries(installedLibraries, licenses)
    )

    const privateComponents = handleChildren(
      app,
      getMarketplaceLibraries(privateLibraries, licenses)
    )

    const usedMarketPlaceComponents = getUsedComponents(
      marketplaceComponents,
      'Marketplace'
    )
    const usedPrivateComponents = getUsedComponents(
      privateComponents,
      'Private'
    )

    return [
      {
        label: 'Installed In App',
        children: [...usedMarketPlaceComponents, ...usedPrivateComponents],
      },
    ]
  }

  const marketplaceOptions = getMarketplaceOptions()
  const componentsByScreen = getComponentsByScreen(marketplaceOptions)

  const isEmpty = !(marketplaceOptions.length > 0)

  const headerImage = isEmpty ? MarketplaceHeaderImage : null
  const color = isEmpty ? 'teal' : 'grey'

  const callout = {
    color,
    title: 'More Amazing Components!',
    body: 'Check out the Adalo Marketplace to find\n more components.',
    headerImage,
    button: {
      icon: 'marketplace',
      children: 'Explore Marketplace',
      to: `/apps/${appId}/marketplace`,
    },
  }

  const sections = !hasNewAddMenuWithSections
    ? [
        {
          title: 'Marketplace',
          options: [...marketplaceOptions, ...componentsByScreen],
          callout,
        },
      ]
    : []

  const tabOptions = hasNewAddMenuWithSections
    ? [...options, ...marketplaceOptions, ...componentsByScreen]
    : options

  return (
    <Tab
      defaultOpen={
        hasNewAddMenuWithSections ? ['Most Used Components'] : ['Most Used']
      }
      searchPlaceholder="Search Components..."
      emptyMessage={
        <>
          No components found
          <CalloutCard {...callout} />
        </>
      }
      renderItem={renderItem}
      options={tabOptions}
      sections={sections}
      platform={app?.primaryPlatform}
    />
  )
}

const mapStateToProps = (state, { appId }) => {
  const app = getApp(state, appId)
  const user = getCurrentUser(state)
  const installedLibraries = getInstalledLibraries(state)
  const privateLibraries = getPrivateLibraries(state)
  const adminLibraries = getAdminLibraries(state)
  const topComponents = getTopComponents(state)
  const licenses = getLicenses(state, app?.OrganizationId, appId)

  const isResponsiveApp = app?.primaryPlatform === 'responsive'

  const hasNewAddMenuWithSections =
    getFeatureFlag(state, 'hasNewAddMenuWithSections') && isResponsiveApp

  const hasVideoComponent =
    getFeatureFlag(state, 'hasVideoComponent') && app && app.magicLayout

  const hasTableComponent = getFeatureFlag(state, 'hasTableComponent')

  const adaloAdmin = user?.admin

  const MOST_USED_TITLE = 'Most Used'

  const options = [
    ...getCategorized({ hasVideoComponent, hasTableComponent }),
  ].map(category => {
    if (category.title === 'Navigation') {
      if (isResponsiveApp) {
        category = addComponentToCategory(category, sideNavigationComponent)
        category = addComponentToCategory(category, topNavigationComponent)
      } else {
        category = removeComponentFromCategory(
          category,
          sideNavigationComponent
        )
        category = removeComponentFromCategory(category, topNavigationComponent)
      }
    }

    if (category.title === MOST_USED_TITLE) {
      if (hasNewAddMenuWithSections) {
        category.title = 'Most Used Components'
      }
    }

    return {
      label: category.title,
      children: handleChildren(app, category.components),
    }
  })

  return {
    app,
    options,
    adaloAdmin,
    installedLibraries,
    privateLibraries,
    adminLibraries,
    topComponents,
    hasNewAddMenuWithSections,
    isResponsiveApp,
    licenses,
  }
}

const mapDispatchToProps = {
  setLayersHover,
  setSelection,
  setTool,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ComponentsTab)
)
