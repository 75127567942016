import { useCallback, useEffect, useState } from 'react'
import { compose } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { reduxForm, InjectedFormProps, SubmitHandler } from 'redux-form'
import userflow from 'userflow.js'
import * as Sentry from '@sentry/browser'

import Loading from 'components/Shared/Loading'

import { getCurrentUser, updateCurrentUser } from 'ducks/users'
import {
  getFirstDefaultTemplateIdForPlatform,
  getLastApp,
  requestApps,
} from 'ducks/apps'
import { fetchOrganizations, getOrganizations } from 'ducks/organizations'
import { WebSettings } from 'ducks/apps/App'

import useAppCategoryData from 'hooks/onboarding/useAppCategoryData'

import { adaloBackendAxios, AxiosResponse } from 'utils/io/http/axios'
import { getNewAppWebSettings } from 'utils/webSettings'
import { defaultBranding } from 'utils/colors'
import { createOnboardingApp as performCreateOnboardingApp } from 'utils/io'

import AppCategoryStep, { AppCategoryGraphic } from './Steps/AppCategoryStep'
import AppPlatformStep, {
  AppPlatformStepGraphic,
} from './Steps/AppPlatformStep'
import SampleDataStep, {
  SampleDataGraphics,
  SampleDataProps,
} from './Steps/SampleDataStep'

import useOnboardingSteps, { OnboardingStep } from './useOnboardingSteps'
import OnboardingLayout from './Layout'
import FormLayout from './FormLayout'
import FooterNav from './FooterNav'

const TEMPLATE_ID = process.env['REACT_APP_ONBOARDING_TEMPLATE_APP_ID'] ?? ''

export const SKIP_APP_CATEGORY = 'Starter'

const FORM_NAME = 'user-questions-form'

export type OnboardingFormValues = {
  appCategory?: string
  addSampleData?: boolean
  appLayoutMode?: WebSettings['layoutMode']
}

type OnboardingSubmitHandler = SubmitHandler<
  OnboardingFormValues,
  Props,
  string
>

type Props = {
  loading?: boolean
}

type UserOnboardingForm = React.FC<
  Props & InjectedFormProps<OnboardingFormValues, Props>
>

type CreateAppParams = {
  templateId?: string
  appLayoutMode?: WebSettings['layoutMode'] | undefined
  appCategory?: string
  dataOption: SampleDataProps['dataOption']
}

const useCreateOnboardingApp = (params: CreateAppParams) => {
  const [loading, setLoading] = useState(false)
  const primaryPlatform = 'responsive' // Onboarding app is always responsive
  const {
    templateId: templateIdParam,
    appLayoutMode = 'responsive',
    appCategory = 'skip',
    // dataOption = 'sample',
  } = params

  const dispatch = useDispatch()
  const organizations = useSelector(getOrganizations)

  useEffect(() => {
    dispatch(fetchOrganizations())
  }, [dispatch, primaryPlatform])

  const createOnboardingApp = async () => {
    let datasourceAppId = templateIdParam
    if (!datasourceAppId) {
      if (TEMPLATE_ID) {
        datasourceAppId = TEMPLATE_ID
      } else {
        datasourceAppId = getFirstDefaultTemplateIdForPlatform(primaryPlatform)
      }
    }

    const organizationId = organizations?.[0]?.id

    if (!organizationId) {
      // eslint-disable-next-line no-alert
      return window.alert('Error: You are not part of any teams')
    }

    setLoading(true)

    const webSettings = getNewAppWebSettings({
      primaryPlatform,
      mobileOnly: appLayoutMode === 'mobile',
    })

    const name = `${appCategory} App`
    const appOpts = {
      baseAppId: datasourceAppId,
      name,
      organizationId,
      primaryPlatform,
      webSettings,
      branding: { ...defaultBranding },
    }

    if (appCategory) {
      Object.assign(appOpts, { appCategory })
    }

    await performCreateOnboardingApp(appOpts, (err: Error) => {
      if (err) {
        console.error(err)
      }
    }).finally(() => setLoading(false))
  }

  return { loading, createOnboardingApp }
}

const getProgressBarPosition = (
  step: OnboardingStep,
  appCategory: string | undefined,
  dataOption: string | undefined,
  appLayoutMode: string | undefined
) => {
  let progressBarPosition = 0
  if (step === OnboardingStep.AppCategory) {
    progressBarPosition = appCategory ? 0.333 : 0.166
  } else if (step === OnboardingStep.SampleData) {
    progressBarPosition = dataOption ? 0.666 : 0.5
  } else if (step === OnboardingStep.AppPlatform) {
    progressBarPosition = appLayoutMode ? 1 : 0.833
  }

  return progressBarPosition
}

type UpdateUserData = {
  dataOption?: string
  persona?: string
} & Omit<Partial<OnboardingFormValues>, 'addSampleData'>

const UserOnboarding: UserOnboardingForm = props => {
  const { step, setStep } = useOnboardingSteps()
  const [appLayoutMode, setAppLayoutMode] =
    useState<WebSettings['layoutMode']>()
  const [appCategory, setAppCategory] = useState('')
  const [isAppCategoryCustom, setIsAppCategoryCustom] = useState(false)
  const [addSampleData] = useState(true)
  const [dataOption, setDataOption] =
    useState<SampleDataProps['dataOption']>('')
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [appCategories, setAppCategories] = useState<string[]>([])
  const [disableForm, setDisableForm] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const currentUser = useSelector(getCurrentUser)
  const lastApp = useSelector(getLastApp)

  const fetchAppCategories = useCallback(async () => {
    const { data } = (await adaloBackendAxios
      .get(`/app-categories?persona=Aspiring Founder`)
      .catch(err => {
        if (err instanceof Error) {
          console.error('Error fetching app categories', err.message)
        }
      })
      .then(result => result)) as AxiosResponse<string[]>

    setAppCategories(data)
    setLoadingCategories(false)
  }, [])

  useEffect(() => {
    if (lastApp || !currentUser) {
      return history.push('/')
    }

    if (currentUser) {
      fetchAppCategories().catch(() => {})
    }
  }, [currentUser, lastApp])

  useEffect(() => {
    dispatch(requestApps())
  }, [])

  const appCategoryData = useAppCategoryData(appCategory)

  const { loading: createAppLoading, createOnboardingApp } =
    useCreateOnboardingApp({
      appCategory,
      dataOption,
      appLayoutMode,
    })

  const { loading: loadingProp } = props
  const loading = loadingProp || createAppLoading

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createOnboardingUserApp = async (_values: OnboardingFormValues) => {
    setDisableForm(true)

    const userId = currentUser?.id

    if (!userId) {
      return history.push('/login')
    }

    await createOnboardingApp()
  }

  // submit can't be triggered in the form as it's set up right now (all is done through the next button)
  const handleSubmit: OnboardingSubmitHandler = values => {
    if (values && typeof values === 'object') {
      createOnboardingUserApp(values as OnboardingFormValues).catch(
        (err: Error) => {
          console.warn(`Failed to finish onboarding: ${err.message}`)
        }
      )
    }
  }

  const onNext = () => {
    const userId = currentUser?.id

    const updateUser = (data: UpdateUserData) => {
      if (userId) {
        dispatch(updateCurrentUser(userId, data))
      }
    }

    if (step === OnboardingStep.AppCategory) {
      userflow
        .updateUser({
          onboarding_main_collection:
            appCategoryData?.mainCollectionName ?? null,
        })
        .catch((err: Error) => {
          console.error(`Failed to update userflow user: ${err.message}`)
          Sentry.captureMessage('ERROR UPDATING USERFLOW USER', {
            user: {
              email: currentUser?.email || '',
            },
            level: 'error',
            extra: {
              error: err.message,
            },
          })
        })

      updateUser({ appCategory, persona: 'Maker' })

      setStep(OnboardingStep.SampleData)
    } else if (step === OnboardingStep.SampleData) {
      updateUser({ dataOption })

      setStep(OnboardingStep.AppPlatform)
    } else if (step === OnboardingStep.AppPlatform) {
      const values: OnboardingFormValues = {
        appCategory,
        addSampleData,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appLayoutMode: appLayoutMode!,
      }

      updateUser({ appLayoutMode })

      createOnboardingUserApp(values).catch((err: Error) => {
        console.warn(`Failed to finish onboarding: ${err.message}`)
      })
    }
  }

  const onPrevious = () => {
    switch (step) {
      case OnboardingStep.AppCategory:
        return
      case OnboardingStep.SampleData:
        setStep(OnboardingStep.AppCategory)

        break
      case OnboardingStep.AppPlatform:
        setStep(OnboardingStep.SampleData)

        break
      default:
        break
    }
  }

  const nextEnabled =
    (step === OnboardingStep.AppCategory && appCategory !== '') ||
    (step === OnboardingStep.SampleData && dataOption !== '') ||
    (step === OnboardingStep.AppPlatform && Boolean(appLayoutMode))

  const previousEnabled = step !== OnboardingStep.AppCategory && !loading
  const showPrevious = step !== OnboardingStep.AppCategory && !loading

  const progressBarPosition = getProgressBarPosition(
    step,
    appCategory,
    dataOption,
    appLayoutMode
  )

  let rightColumnGraphic: JSX.Element | undefined
  let currentStep: JSX.Element | undefined

  if (step === OnboardingStep.AppCategory) {
    currentStep = (
      <AppCategoryStep
        initialCategory={appCategory}
        setAppCategory={setAppCategory}
        loading={loadingCategories}
        appCategories={appCategories}
        setIsAppCategoryCustom={setIsAppCategoryCustom}
      />
    )
    rightColumnGraphic = (
      <AppCategoryGraphic
        isCustom={isAppCategoryCustom}
        appCategory={appCategory}
        userName={currentUser?.name}
      />
    )
  } else if (step === OnboardingStep.SampleData) {
    currentStep = (
      <SampleDataStep
        dataOption={dataOption}
        setDataOption={setDataOption}
        mainCollectionName={appCategoryData?.mainCollectionName ?? appCategory}
      />
    )
    rightColumnGraphic = (
      <SampleDataGraphics dataOption={dataOption} {...appCategoryData} />
    )
  } else if (step === OnboardingStep.AppPlatform) {
    currentStep = (
      <AppPlatformStep
        setAppLayoutMode={setAppLayoutMode}
        disabled={disableForm}
      />
    )
    rightColumnGraphic = (
      <AppPlatformStepGraphic appLayoutMode={appLayoutMode} />
    )
  }

  if (!currentUser) {
    return (
      <OnboardingLayout>
        <Loading expanded />
      </OnboardingLayout>
    )
  }

  return (
    <OnboardingLayout>
      <FormLayout>
        <form onSubmit={handleSubmit}>{currentStep}</form>
        <FooterNav
          progressBarPosition={progressBarPosition}
          nextLoading={loading}
          showNext
          showPrevious={showPrevious}
          nextEnabled={nextEnabled}
          previousEnabled={previousEnabled}
          onNext={onNext}
          onPrevious={onPrevious}
        />
      </FormLayout>
      {rightColumnGraphic}
    </OnboardingLayout>
  )
}

const WrappedUserOnboarding = compose(
  reduxForm<OnboardingFormValues, Props>({
    form: FORM_NAME,
  })
)(UserOnboarding)

export default WrappedUserOnboarding
