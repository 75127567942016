import Page from 'components/Shared/Page'
import Body from 'components/App/Body'
import {
  setAnnualFlag,
  setClosableFlag,
  setPlanSelectedFlag,
  setSelectedPlanValue,
  showOrganizationDetails,
} from 'ducks/trialWarning'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
  fetchOrganization,
  getCurrentOrganization,
  setCurrentOrganization,
  fetchOrganizations,
} from 'ducks/organizations'
import TrialModal from 'components/Shared/TrialWarning/TrialModal'
import { useHistory, useParams } from 'react-router-dom'

import './MaintenanceMode.scss'
import QS from 'qs'
import { getPricingPlans } from '../../ducks/billing'
import Button from '../Shared/Button'
import { getCurrentUser } from '../../ducks/users'
import Loading from '../Shared/Loading'

const SELECTED_PLAN = 'maintenance'

const MaintenanceMode = () => {
  const { organizationId } = useParams()
  const currentOrganization = useSelector(getCurrentOrganization)
  const currentUser = useSelector(getCurrentUser)
  const pricingPlans = useSelector(getPricingPlans)
  const dispatch = useDispatch()
  const history = useHistory()

  const { sessionToken } = QS.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (currentUser && !sessionToken) {
      const loadOrganizationData = async () => {
        await dispatch(fetchOrganization(organizationId))
        setLoading(false)
        dispatch(setCurrentOrganization(organizationId))
      }

      dispatch(setSelectedPlanValue(SELECTED_PLAN))
      dispatch(setAnnualFlag(false))
      dispatch(setPlanSelectedFlag(true))
      dispatch(setClosableFlag(false))
      dispatch(showOrganizationDetails(true))
      loadOrganizationData()
    }
  }, [currentUser])

  const renderContent = () => {
    if (loading || sessionToken) {
      return <Loading />
    }

    if ((currentOrganization === null && !loading) || !pricingPlans) {
      return (
        <>
          <h1 className="page-not-found">404</h1>
          <p>Oops... This Page is Not Found!</p>
          <p className="page-not-found">
            Please, check the address for any mistypes.
          </p>
          <Button
            small
            onClick={() => {
              return history.push(`/`)
            }}
          >
            Go to Adalo builder
          </Button>
        </>
      )
    }

    return (
      <TrialModal
        organizationId={organizationId}
        forceRefresh={fetchOrganizations}
        initialValues={{ plan: SELECTED_PLAN, annual: false }}
      />
    )
  }

  return (
    <Body>
      <Page title="Choose Pricing Plan">
        <div className="choose-pricing-plan-container">{renderContent()}</div>
      </Page>
    </Body>
  )
}

export default MaintenanceMode
