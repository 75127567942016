import React from 'react'
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getCurrentUser } from 'ducks/users/index.ts'

import Dashboard from './Dashboard.tsx'
import Users from './Users.tsx'
import Apps from './Apps'
import Datasource from './Datasource'
import DeletedApp from './DeletedApp'
import Builds from './Builds'
import AppScreens from './AppScreens'
import { Libraries, Library } from './Marketplace'

const AdminRoutes = () => {
  const user = useSelector(state => getCurrentUser(state))

  // redirect non-admin user
  if (user && !user.admin) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div style={{ marginBottom: '10px', padding: '20px' }}>
        <NavLink to="/admin">Dashboard</NavLink>
        {` • `}
        <NavLink to="/admin/users">Users</NavLink>
        {` • `}
        <Link to="/admin/marketplace">Marketplace Libraries</Link>
        {` • `}
        <Link to="/admin/builds">Builds</Link>
      </div>
      <Switch>
        <Route
          exact
          path="/admin"
          component={() => <Redirect to="/admin/dashboard" />}
        />
        <Route path="/admin/dashboard" component={Dashboard} />
        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/users/:userId" component={Apps} />
        <Route exact path="/admin/marketplace" component={Libraries} />
        <Route
          exact
          path="/admin/datasource/:datasourceId"
          component={Datasource}
        />
        <Route exact path="/admin/app-screens/:appId" component={AppScreens} />
        <Route exact path="/admin/deleted-app/:appId" component={DeletedApp} />
        <Route
          exact
          path={[
            '/admin/marketplace/:libraryName',
            '/admin/marketplace/:libraryAuthor/:libraryName',
          ]}
          component={Library}
        />
        <Route exact path="/admin/builds" component={Builds} />
      </Switch>
    </>
  )
}

export default AdminRoutes
