import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import { getId as generateUuid } from '@adalo/utils'
import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

import { newAction } from 'utils/actions'

import { getUpgraded, getDatasourceType, getTrialInformation } from 'ducks/apps'
import { openAccordion } from 'ducks/accordions'
import { getActions, updateObject } from 'ducks/editor/objects'

import Button from 'components/Shared/Button'

import Item from './Item'
import EmptyState from './EmptyState'

class ActionRow extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    actions: PropTypes.objectOf(PropTypes.shape({})),
    componentId: PropTypes.string.isRequired,
    datasourceType: PropTypes.string.isRequired,
    updateObject: PropTypes.func.isRequired,
    openAccordion: PropTypes.func.isRequired,
  }

  handleCreate = defaults => {
    const { openAccordion } = this.props

    const actionItem = newAction(defaults)

    openAccordion('action-item', actionItem.actions[0].id)

    this.handleUpdate(generateUuid())(actionItem)
  }

  handleUpdate = id => action => {
    const { actions, updateObject, object } = this.props

    if (!action.actions || action.actions.length === 0) {
      this.handleRemove(id)()
    } else if (!deepEqual(actions[id], action)) {
      const newActions = {
        ...actions,
        [id]: action,
      }
      updateObject(object.id, { actions: newActions })
    }
  }

  handleRemove = id => () => {
    const { updateObject, object } = this.props
    let { actions } = this.props

    actions = {
      ...actions,
      [id]: undefined,
    }

    updateObject(object.id, { actions })
  }

  render() {
    const {
      actions,
      appId,
      componentId,
      displayName,
      object,
      datasourceType,
      isAfterTrial,
    } = this.props

    const actionKeys = Object.keys(actions)

    const tooltip = (
      <a
        href="https://help.adalo.com/action-basics"
        target="_blank"
        rel="noreferrer"
      >
        Learn more.
      </a>
    )

    return (
      <div
        className={
          isAfterTrial
            ? 'library-inspect-action after-trial-action'
            : 'library-inspect-action'
        }
      >
        <div className="action-row-header">
          <p className="library-inspect-action-title">{displayName}</p>
          <Tooltip tooltip={tooltip} hideArrow small>
            <Icon type="help-small" />
          </Tooltip>
        </div>
        {actionKeys.length === 0 ? (
          <EmptyState
            onCreate={this.handleCreate}
            appId={appId}
            componentId={componentId}
            datasourceType={datasourceType}
            objectId={object?.id}
          >
            <Button icon="plus-small" listAddButton gray>
              Add Action
            </Button>
          </EmptyState>
        ) : (
          actionKeys.map(id => (
            <Item
              key={id}
              id={id}
              action={actions[id]}
              onRemove={this.handleRemove(id)}
              onUpdate={this.handleUpdate(id)}
              appId={appId}
              componentId={componentId}
              objectId={object.id}
              datasourceType={datasourceType}
            />
          ))
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, { appId, object }) => {
  let actions = {}

  const { trialState } = getTrialInformation(state, appId)
  const paying = getUpgraded(state, appId)

  const isAfterTrial =
    !paying && (trialState === 'after' || trialState === 'before')

  if (object) {
    actions = getActions(state, object.id)
  }

  return {
    actions,
    datasourceType: getDatasourceType(state, appId),
    isAfterTrial,
  }
}

export default connect(mapStateToProps, { updateObject, openAccordion })(
  ActionRow
)
