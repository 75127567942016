import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import './Dashboard.scss'

interface ChartProps {
  data: Array<{ date: string; count: number }>
  title: string
  desc?: string
  barColor: string
  xKey?: string
  yKey?: string
}

const Chart: React.FC<ChartProps> = ({
  data,
  title,
  desc,
  barColor,
  xKey = 'date',
  yKey = 'count',
}) => {
  return (
    <div className="chart-container">
      <h2>{title}</h2>
      {desc && <p>{desc}</p>}
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xKey} />
          <YAxis />
          <Tooltip />
          <Bar dataKey={yKey} fill={barColor} radius={[8, 8, 8, 8]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Chart
