import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'
import QS from 'qs'
import { cloneDeep, get } from 'lodash'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { getBindingTestValue, getBindingLabel } from 'utils/bindings'
import Button from 'components/Shared/Button'
import { PLAN_FREE } from '@adalo/constants'
import { FORM_NAME } from './Form'
import Literal from './Literal'
import './TestConnection.scss'
import { getPath } from './utils'
import { getCurrentOrganization } from '../../../../ducks/organizations'

class TestConnection extends Component {
  state = { fetching: false }

  startTest = async () => {
    const {
      endpoints,
      auth,
      planType,
      input: { onChange },
    } = this.props

    const {
      list: { url, method },
    } = endpoints

    onChange({
      endpoints,
      auth,
      planType,
    })

    const testUrl = getBindingTestValue(url)
    let testAuth

    if (auth) {
      testAuth = cloneDeep(auth)

      for (const authItem of testAuth) {
        authItem.value = getBindingTestValue(authItem.value)
      }
    }

    const query = QS.stringify({
      url: testUrl,
      auth: testAuth,
      method,
    })

    this.setState({ fetching: true })

    const sheetDbApiUrl = 'https://sheetdb.io/api/'
    const isSpreadsheetsConnection = testUrl
      .toLowerCase()
      .startsWith(sheetDbApiUrl)

    if (planType === PLAN_FREE && !isSpreadsheetsConnection) {
      this.setState({ fetching: false })

      const errorMessage = () => (
        <>
          You can use integration with the only{' '}
          <a href={'https://sheetdb.io'} target="_blank" rel="noreferrer">
            https://sheetdb.io
          </a>{' '}
          service.
        </>
      )

      return onChange({
        success: false,
        error: errorMessage(),
        timestamp: +new Date(),
      })
    }

    const response = await adaloBackendAxios.get(`/test-api-endpoint?${query}`)
    this.setState({ fetching: false })

    let results = null

    const resultPath = getPath(endpoints, 'list')

    if (response.data.response && response.data.success) {
      results = response.data.response.data

      if (resultPath.length > 0) {
        results = get(results, resultPath)
      }

      if (!Array.isArray(results)) {
        return onChange({
          ...response.data,
          success: false,
          error:
            'Could not find any results. ' +
            'Are you sure the Results Key is correct? ' +
            'You can update that by going back and clicking into "Get All"',
          timestamp: +new Date(),
        })
      } else if (results.length === 0) {
        return onChange({
          ...response.data,
          success: false,
          error:
            'Could not get any results. ' +
            'Please make sure that your endpoint returns at least one ' +
            'result, so that we can grab the fields.',
          timestamp: +new Date(),
        })
      }
    }

    onChange({
      ...response.data,
      results,
      timestamp: +new Date(),
    })
  }

  renderInitial() {
    const { fetching } = this.state

    return (
      <div className="test-connection-initial">
        <h2>Test API Setup</h2>
        <p>
          {`When you're ready we'll do a Get All to your API
            to make sure everything is good to go.`}
        </p>
        <Button type="button" onClick={this.startTest} loading={fetching}>
          Run Test
        </Button>
      </div>
    )
  }

  renderError() {
    const { fetching } = this.state

    const {
      input: { value },
    } = this.props

    const response = value.response || {}
    const { error } = value

    return (
      <div className="test-connection-results">
        <Button
          type="button"
          onClick={this.startTest}
          loading={fetching}
          className="test-connection-button"
        >
          Try Again
        </Button>
        <h2>An error occurred when calling Get All</h2>
        <h3>{error}</h3>
        <p>URL Called:</p>
        <pre>
          <Field
            component={Literal}
            name="endpoints.list.url"
            formatter={getBindingLabel}
          />
        </pre>
        {response.data && (
          <>
            <p>Full Response:</p>
            <pre>{JSON.stringify(response.data, null, 2)}</pre>
          </>
        )}
      </div>
    )
  }

  renderSuccess() {
    const {
      input: { value },
    } = this.props

    return (
      <div className="test-connection-results">
        <h2>Test Successful!</h2>
        <p>Here's the full response we got back:</p>
        <pre>{JSON.stringify(value.response.data, null, 2)}</pre>
      </div>
    )
  }

  render() {
    const {
      input: { value },
    } = this.props

    const { fetching } = this.state

    if (!value || fetching) {
      return this.renderInitial()
    } else if (!value.success) {
      return this.renderError()
    }

    return this.renderSuccess()
  }
}

const mapStateToProps = (state, { uniqueId }) => {
  const selector = formValueSelector(FORM_NAME)
  const currentOrganization = getCurrentOrganization(state)

  return {
    endpoints: selector(state, 'endpoints'),
    auth: selector(state, 'auth'),
    planType: currentOrganization?.planType,
  }
}

export default connect(mapStateToProps, {})(TestConnection)
